/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import dayjs from "dayjs";
import isObject from "lodash/isObject";

import DatePicker from "react-datepicker";
import { isValidPhoneNumber } from "libphonenumber-js/min";

import { get, map } from "lodash";

import { useMutation, useQuery } from "@apollo/client";

import { toast } from "react-toastify";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useToggle } from "react-use";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Input from "../../../../components/Input";
import PlanBlock from "../../../../components/PlanBlock";

import {
  QUERY_COMPANIES,
  QUERY_EMPLOYEE,
  QUERY_EMPLOYEES,
  QUERY_CODE_CHECK,
  QUERY_MEMBER_GROUPS,
} from "../../../../config/graphql/query";
import {
  MUTATION_CREATE_EMPLOYEE,
  MUTATION_UPDATE_EMPLOYEE,
  MUTATION_CREATE_MEMBER_ACCESS,
  MUTATION_RESEND_MEMBER_ACCESS,
} from "../../../../config/graphql/mutation";

import { useCurrentClient } from "../../../../context/Client";

export const reactSelectCustomStyles = (invalid: boolean) => {
  return {
    control: (provided: any, state: any) => ({
      ...provided,
      border: invalid
        ? "1px solid #dc3545"
        : !state.isFocused
        ? "1px solid #ced4da"
        : "1px solid #80bdff",
      boxShadow: state.isFocused
        ? invalid
          ? "0 0 0 0.2rem rgb(220 53 69 / 25%)"
          : "0 0 0 0.2rem rgb(0 123 255 / 25%)"
        : "",
      "&:hover": {
        borderColor: "1px solid #ced4da",
      },
    }),
  };
};

type FieldValues = {
  company: { id: string };
  fullName: string;
  email: string;
  phone?: string;
  groups?: string[];
  skipAccess: boolean;
  access: {
    id?: string;
    fallbackCode?: string;
    validFrom?: Date;
    validUntil?: Date;
  };
};

const EmployeeAccessForm = () => {
  const { t } = useTranslation(["employee", "common"]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { refetch } = useQuery<{ fallbackCodeCheck: { available: boolean } }>(
    QUERY_CODE_CHECK,
    {
      skip: true,
    },
  );

  const { watch, setValue, getValues } = useFormContext<FieldValues>();

  const watchValidFrom = watch("access.validFrom");

  return (
    <div>
      <div className="form-group">
        <label htmlFor="validFrom">
          {t("employee:input.label.access.validFrom")}
        </label>
        <Controller
          name="access.validFrom"
          rules={{
            validate: {
              validate: (value) => {
                if (!value) {
                  return true;
                }

                const current = dayjs(value);

                if (!current.isValid()) {
                  return t("employee:input.error.access.validFrom.format");
                }

                if (current.isBefore(new Date())) {
                  return t("employee:input.error.access.validFrom.beforeToday");
                }

                return true;
              },
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <div
                className={clsx({
                  "is-invalid": !!error,
                })}
              >
                <DatePicker
                  showTimeSelect
                  selected={value}
                  onChange={(nextDate) => {
                    const validUntil = getValues()?.access?.validUntil;

                    if (
                      validUntil &&
                      dayjs(validUntil).isValid() &&
                      dayjs(nextDate).isAfter(validUntil)
                    ) {
                      const diff = dayjs(nextDate).diff(watchValidFrom, "days");

                      setValue(
                        "access.validUntil",
                        dayjs(validUntil).add(diff, "days").toDate(),
                      );
                    }

                    onChange(nextDate);
                  }}
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy p"
                  className={clsx("form-control", {
                    "is-invalid": !!error,
                  })}
                />
              </div>

              <div className="invalid-feedback">{error?.message}</div>
            </>
          )}
        />
      </div>
      <div className="form-group">
        <label htmlFor="validUntil">
          {t("employee:input.label.access.validUntil")}
        </label>
        <div>
          <Controller
            name="access.validUntil"
            rules={{
              validate: {
                validate: (value) => {
                  if (!value) {
                    return true;
                  }

                  const current = dayjs(value);

                  if (!current.isValid()) {
                    return t("employee:input.error.access.validUntil.format");
                  }

                  if (current.isBefore(watchValidFrom)) {
                    return t(
                      "employee:input.error.access.validUntil.beforeUntil",
                    );
                  }

                  return true;
                },
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <div
                  className={clsx({
                    "is-invalid": !!error,
                  })}
                >
                  <DatePicker
                    showTimeSelect
                    selected={value}
                    onChange={onChange}
                    minDate={watchValidFrom || new Date()}
                    dateFormat="dd/MM/yyyy p"
                    className={clsx("form-control", {
                      "is-invalid": !!error,
                    })}
                  />
                </div>
                <div className="invalid-feedback">{error?.message}</div>
              </>
            )}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="fallbackCode">
          {t("employee:input.label.access.fallbackCode")}
        </label>
        <Input
          name="access.fallbackCode"
          className="form-control"
          maxLength={6}
          rules={{
            minLength: {
              value: 6,
              message: t("employee:input.error.access.fallbackCode.minLength", {
                count: 6,
              }),
            },
            maxLength: {
              value: 6,
              message: t("employee:input.error.access.fallbackCode.maxLength", {
                count: 6,
              }),
            },
            validate: {
              format: (value) => {
                if (!value) {
                  return true;
                }

                return (
                  /^[0-9]+$/.test(value) ||
                  t("employee:input.error.access.fallbackCode.format")
                );
              },
              exists: async (value) => {
                if (!value) {
                  return true;
                }

                const available = await refetch({ code: value }).then(
                  ({ data }) => data?.fallbackCodeCheck.available,
                );

                return (
                  available ||
                  t("employee:input.error.access.fallbackCode.exists")
                );
              },
            },
          }}
        />
        <small id="fallbackCode" className="form-text text-muted">
          {t("employee:input.helper.access.fallbackCode")}
        </small>
      </div>
    </div>
  );
};

const Employee = React.memo(() => {
  const history = useHistory();

  const [show, setShow] = useToggle(false);

  const { t } = useTranslation(["employee", "common"]);

  const { id } = useParams<{ id: string }>();

  const currentClient = useCurrentClient();

  const skipAccessDefault = !get(
    currentClient,
    "preferences.memberAccess.employee.enabled",
  );

  const { data: employeeData } = useQuery<{ employee: IMemberEmployee }>(
    QUERY_EMPLOYEE,
    {
      skip: !id,
      variables: { id },
    },
  );

  const { data: groupsData } = useQuery<{
    memberGroups: IMemberGroup[];
  }>(QUERY_MEMBER_GROUPS, {
    fetchPolicy: "network-only",
  });

  const groupsSelectOptions = useMemo(
    () =>
      map(groupsData?.memberGroups, (group) => ({
        value: group.id,
        label: group.title,
      })),
    [groupsData],
  );

  const [onUpdate] = useMutation(MUTATION_UPDATE_EMPLOYEE, {
    refetchQueries: [
      {
        query: QUERY_EMPLOYEES,
      },
    ],
  });

  const [onCreate] = useMutation(MUTATION_CREATE_EMPLOYEE, {
    refetchQueries: [
      {
        query: QUERY_EMPLOYEES,
      },
    ],
  });

  const { data: companiesData } = useQuery<{ companies: ICompany[] }>(
    QUERY_COMPANIES,
    {
      nextFetchPolicy: "network-only",
    },
  );

  const companyOptions = useMemo(
    () => companiesData?.companies ?? [],
    [companiesData],
  );

  const methods = useForm<FieldValues>({
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      company: companyOptions?.[0],
      fullName: "",
      email: "",
      phone: "",
      groups: undefined,
      skipAccess: skipAccessDefault,
      access: {
        id: undefined,
        fallbackCode: "",
        validFrom: undefined,
        validUntil: undefined,
      },
    },
  });

  useEffect(() => {
    if (!id && companyOptions) {
      methods.setValue("company", companyOptions?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyOptions, id]);

  useEffect(() => {
    if (employeeData?.employee) {
      methods.reset({
        ...employeeData?.employee,
        phone: employeeData?.employee.phone ?? "",
        groups: employeeData?.employee?.groups?.map((group) => group.id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeData]);

  const [resendAccess, { loading }] = useMutation(
    MUTATION_RESEND_MEMBER_ACCESS,
    {
      onCompleted: () => {
        toast.success<string>(t("employee:toast.resendCredentials"));
        setShow(false);
      },
      onError() {
        toast.error<string>(t("employee:toast.resendCredentialsError"));
        setShow(false);
      },
    },
  );

  const [onCreateMemberAccess] = useMutation(MUTATION_CREATE_MEMBER_ACCESS, {
    onCompleted: () => {
      toast.success<string>(t("employee:toast.createCredentials"));
    },
    onError() {
      toast.error<string>(t("employee:toast.createCredentialsError"));
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: QUERY_EMPLOYEE, variables: { id } }],
  });

  const onSendCredentials = (accessId: string) => {
    resendAccess({ variables: { id: accessId } });
  };

  const skipAccess = methods.watch("skipAccess");

  const access = methods.getValues("access");

  const onSubmit = async (variables: FieldValues) => {
    const { company, access, phone, fullName, email, ...restOfVariables } =
      variables;

    if (id) {
      return onUpdate({
        variables: {
          input: {
            id,
            fullName: fullName.trim(),
            email: email.trim(),
            ...(!!phone ? { phone } : { phone: null }),
            ...restOfVariables,
          },
        },
      })
        .then(
          ({
            data: {
              updateEmployee: { fullName },
            },
          }) => {
            toast.success<string>(t("employee:toast.updated", { fullName }));
          },
        )
        .catch(() => {
          toast.error<string>(t("employee:toast.updatedError"));
        });
    }

    const isEmptyAccess = !(isObject(access) && Object.keys(access).length > 0);

    const input = {
      company: get(company, "id"),
      fullName: fullName.trim(),
      email: email.trim(),
      ...(!isEmptyAccess && { access }),
      ...(!!phone ? { phone: phone.trim() } : { phone: null }),
      ...restOfVariables,
    };

    return onCreate({ variables: { input } })
      .then(
        ({
          data: {
            createEmployee: { id, fullName },
          },
        }) => {
          toast.success<string>(t("employee:toast.created", { fullName }));
          history.replace(`/employees/${id}`);
        },
      )
      .catch(() => {
        toast.error<string>(t("employee:toast.createdError"));
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <h6 className="mb-4">
                {t("employee:section.information.title")}
              </h6>
              <Controller
                name="company"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="form-group">
                    <label htmlFor="company">
                      {t("employee:input.label.company")}
                    </label>
                    <Select
                      getOptionLabel={({ title }) => title}
                      getOptionValue={({ id }) => id}
                      options={companyOptions}
                      onChange={(value) => onChange(value)}
                      value={value}
                      className={clsx({
                        "is-invalid": !!error,
                      })}
                      styles={reactSelectCustomStyles(!!error)}
                      isDisabled={!!id}
                    />
                    <div className="invalid-feedback">{error?.message}</div>
                  </div>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("employee:input.error.company.required"),
                  },
                }}
              />

              {employeeData?.employee.photo?.absolutePath && (
                <div className="mb-3 d-flex flex-column">
                  <div className="mb-2">{t("employee:input.label.photo")}</div>
                  <img
                    src={employeeData?.employee.photo?.absolutePath}
                    className="rounded-lg"
                    style={{ width: "150px", height: "150px" }}
                    alt={t("employee:input.label.photo")}
                  />
                </div>
              )}

              <div className="form-group">
                <label htmlFor="fullName">
                  {t("employee:input.label.fullName")}
                </label>
                <Input
                  name="fullName"
                  className="form-control"
                  disabled={!!employeeData?.employee?.externalSource?.id}
                  rules={{
                    required: {
                      value: true,
                      message: t("employee:input.error.fullName.required"),
                    },
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">{t("employee:input.label.email")}</label>
                <Input
                  name="email"
                  className="form-control"
                  autoComplete="email"
                  disabled={!!employeeData?.employee?.externalSource?.id}
                  rules={{
                    required: {
                      value: true,
                      message: t("employee:input.error.email.required"),
                    },
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">{t("employee:input.label.phone")}</label>
                <Input
                  name="phone"
                  className="form-control"
                  rules={{
                    validate: {
                      invalid: (value) => {
                        if (!value) {
                          return true;
                        }
                        return (
                          isValidPhoneNumber(value) ||
                          t("employee:input.error.phone.format")
                        );
                      },
                    },
                  }}
                />
                <small className="form-text text-muted small">
                  {t("employee:input.label.phone-help")}
                </small>
              </div>
              <PlanBlock exclude={["free"]}>
                <Controller
                  name="groups"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div className="form-group">
                      <label htmlFor="groups">
                        {t("employee:input.label.groups")}
                      </label>
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={groupsSelectOptions}
                        onChange={(nextValue) => {
                          onChange(map(nextValue, "value"));
                        }}
                        value={map(value, (value) => ({
                          value,
                          label: groupsSelectOptions.find(
                            (option) => option.value === value,
                          )?.label,
                        }))}
                        className={clsx({
                          "is-invalid": !!error,
                        })}
                      />
                      {!!error && (
                        <div className="invalid-feedback">{error.message}</div>
                      )}
                    </div>
                  )}
                />
              </PlanBlock>
              <div className="row">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary mt-2 mb-4"
                    disabled={methods.formState.isSubmitting}
                  >
                    {t("employee:button.save")}
                  </button>
                </div>
              </div>
            </div>
            {!skipAccessDefault && (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <h6 className="mb-3">{t("employee:section.access.title")}</h6>
                {!id && (
                  <Controller
                    name="skipAccess"
                    render={({
                      field: { value, name, onChange },
                      fieldState: { error },
                    }) => (
                      <div className="form-group form-check my-3">
                        <input
                          id={name}
                          type="checkbox"
                          className="form-check-input"
                          checked={value}
                          onChange={onChange}
                        />
                        <label
                          className="form-check-label user-select-none"
                          htmlFor={name}
                        >
                          {t("employee:input.label.access.skip")}
                        </label>
                        {!!error && (
                          <div className="invalid-feedback">
                            {error.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                )}
                {((!id && !skipAccess) ||
                  (id && !employeeData?.employee.access?.id)) && (
                  <EmployeeAccessForm />
                )}

                {id && (
                  <div>
                    {employeeData?.employee.access?.id && (
                      <>
                        {employeeData.employee.access.validFrom && (
                          <>
                            <div>
                              {t("employee:input.label.access.validFrom")}
                            </div>
                            <p>
                              {dayjs(
                                employeeData?.employee.access.validFrom,
                              ).format("YYYY-MM-DD HH:mm")}
                            </p>
                          </>
                        )}
                        {employeeData.employee.access.validUntil && (
                          <>
                            <div>
                              {t("employee:input.label.access.validUntil")}
                            </div>
                            <p>
                              {dayjs(
                                employeeData?.employee.access.validUntil,
                              ).format("YYYY-MM-DD HH:mm")}
                            </p>
                          </>
                        )}
                        <div>
                          {t("employee:input.label.access.fallbackCode")}
                        </div>
                        <p className="mb-2 font-weight-bold">
                          {employeeData?.employee.access?.fallbackCode}
                        </p>
                        {employeeData?.employee.access?.file?.absolutePath && (
                          <>
                            <div className="mb-1">
                              {t("employee:input.label.access.file")}
                            </div>
                            <div
                              style={{
                                width: "200px",
                                height: "200px",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                src={
                                  employeeData?.employee.access?.file
                                    ?.absolutePath
                                }
                                width="270px"
                                height="270px"
                                style={{
                                  transform: "translate(-35px,-35px)",
                                }}
                                alt={t("employee:input.label.access.file")}
                              />
                            </div>
                          </>
                        )}
                        <div className="btn btn-primary mt-4" onClick={setShow}>
                          {t("employee:button.sendCredentials")}
                        </div>
                      </>
                    )}
                    {!employeeData?.employee.access?.id && (
                      <div
                        className="btn btn-primary mt-4"
                        onClick={() =>
                          onCreateMemberAccess({
                            variables: {
                              input: {
                                id,
                                validFrom: access.validFrom,
                                validUntil: access.validUntil,
                                fallbackCode: access.fallbackCode,
                              },
                            },
                          })
                        }
                      >
                        {t("employee:button.createCredentials")}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </form>
      </FormProvider>
      <Modal
        show={show}
        onHide={setShow}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("employee:dialog.resendCredentials.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("employee:dialog.resendCredentials.description", {
            fullName: employeeData?.employee.fullName,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("employee:dialog.resendCredentials.button.dismiss")}
          </Button>
          <Button
            variant="primary"
            disabled={!!loading}
            onClick={() => {
              if (employeeData?.employee.access?.id) {
                onSendCredentials(employeeData?.employee.access?.id);
              }
            }}
          >
            {t("employee:dialog.resendCredentials.button.submit")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export const EmployeeCreate = () => {
  const { t } = useTranslation(["employee", "common"]);

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/employees">{t("employees:title")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("employee:title")}
          </li>
        </ol>
      </nav>
      <Employee />
    </div>
  );
};

export default Employee;

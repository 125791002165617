/* eslint-disable no-restricted-syntax */
import {
  ChangeEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useForm,
  useWatch,
  Controller,
  FieldValues,
  FormProvider,
  ControllerFieldState,
  ControllerRenderProps,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import get from "lodash/get";
import set from "lodash/set";
import map from "lodash/map";
import clsx from "clsx";

import Select from "react-select";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATIONS,
  QUERY_DIGITAL_RECEPTION_SETTINGS,
} from "../../../../../config/graphql/query";
import { UPDATE_DIGITAL_RECEPTION_SETTINGS } from "../../../../../config/graphql/mutation";

import { useCurrentHasPlan } from "../../../../../components/PlanBlock";
import Input from "../../../../../components/Input";
import { reactSelectCustomStyles } from "../../../../Employees/Employee/Information";

import { ReactComponent as WayfindingIcon } from "./icons/Wayfinding.svg";
import { ReactComponent as InfoIcon } from "./icons/Info.svg";
// import { ReactComponent as OverviewIcon } from "./icons/Overview.svg";

const SvgIconMap: Record<number, JSX.Element> = {
  1001: <WayfindingIcon width="1.5rem" />,
  1002: <InfoIcon width="1.5rem" />,
  // 1003: <OverviewIcon width="1.5rem" />,
};

const FormCheckbox = (props: {
  form: {
    field:
      | ControllerRenderProps<FieldValues, `${string}.enabled`>
      | ControllerRenderProps<FieldValues, `${string}.required`>
      | ControllerRenderProps<FieldValues, `${string}.isCustom`>;
    fieldState: ControllerFieldState;
  };
  label: React.ReactNode;
  onChangePreview?: (name: string, value: any) => any;
  isDisabled?: boolean;
}) => {
  const {
    form: {
      field: { value, name, onChange },
      fieldState: { error },
    },
    label,
    onChangePreview,
    isDisabled,
  } = props;

  return (
    <div className="form-group form-check mb-1">
      <input
        id={name}
        type="checkbox"
        className="form-check-input"
        checked={value}
        onChange={(value) => {
          onChange(value);
          if (onChangePreview) {
            onChangePreview(name, value.target.checked);
          }
        }}
        disabled={isDisabled}
      />
      <label className="form-check-label user-select-none" htmlFor={name}>
        {label}
      </label>
      {!!error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
};

const LayoutOptionText = (props: {
  name: string;
  onChangePreview: (name: string, value: any) => any;
  language: string;
}) => {
  const { name, onChangePreview, language } = props;

  const enabled = useWatch({ name: `${name}.enabled` });

  if (!enabled) {
    return null;
  }

  return (
    <div className="form-group my-2">
      <div className="input-group ">
        <div className="input-group-prepend">
          <span className="input-group-text">{language.toUpperCase()}</span>
        </div>
        <Controller
          name={`${name}.text`}
          render={({ field: { onChange, ...props } }) => (
            <input
              onChange={(value) => {
                onChange(value);
                onChangePreview(`${name}.text`, value.target.value);
              }}
              {...props}
              className="form-control"
            />
          )}
        />
      </div>
    </div>
  );
};

const LayoutOptionRegistration = (props: {
  name: string;
  manualRegistrations: IManualRegistration[];
  loading: boolean;
}) => {
  const { name, manualRegistrations, loading } = props;

  const { t } = useTranslation(["screens"]);

  const enabled = useWatch({ name: `${name}.enabled` });

  if (!enabled) {
    return null;
  }

  return (
    <div className="form-group">
      <label>{t("screens:screen.settings.layout.manualRegistration")}</label>
      <Controller
        name={`${name}.registration`}
        render={({ field: { onChange, ...props }, fieldState: { error } }) => (
          <>
            <Select
              getOptionLabel={({ title }) => title}
              getOptionValue={({ id }) => id}
              options={manualRegistrations}
              isClearable
              isLoading={loading}
              onChange={onChange}
              {...props}
            />
            {!!error && (
              <div className="invalid-feedback">{error?.message}</div>
            )}
          </>
        )}
      />
    </div>
  );
};

const LayoutOptionSvg = (props: {
  name: string;
  onChangePreview: (name: string, value: any) => any;
  availableSvgs?: number[];
}) => {
  const { name, onChangePreview, availableSvgs } = props;

  const { t } = useTranslation(["screens"]);

  const enabled = useWatch({ name: `${name}.enabled` });

  if (!enabled || !availableSvgs) {
    return null;
  }

  const availableSvgsOptions = availableSvgs.map((svg) => {
    return { id: svg };
  });

  return (
    <div className="form-group">
      <label>{t("screens:screen.settings.layout.icon")}</label>
      <Controller
        name={`${name}.svg`}
        render={({
          field: { value, onChange, ...props },
          fieldState: { error },
        }) => (
          <>
            <Select
              {...props}
              options={availableSvgsOptions}
              // @ts-ignore
              // eslint-disable-next-line react/no-unstable-nested-components
              getOptionLabel={({ id }) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {SvgIconMap[id] || null}
                </div>
              )}
              getOptionValue={({ id }) => id.toString()}
              value={
                availableSvgsOptions.find((option) => option.id === value) ||
                null
              }
              onChange={(selected) => {
                onChange(selected ? selected.id : null);
                onChangePreview(`${name}.svg`, selected ? selected.id : null);
              }}
              styles={{
                option: (provided, { isSelected }) => ({
                  ...provided,
                  backgroundColor: isSelected
                    ? "#80C8FF"
                    : provided.backgroundColor,
                }),
              }}
            />
            {!!error && (
              <div className="invalid-feedback">{error?.message}</div>
            )}
          </>
        )}
      />
    </div>
  );
};

interface LayoutOptionControllerProps extends PropsWithChildren {
  name: string;
  label: string;
  onChangePreview: (name: string, value: any) => void | undefined;
  language?: string;
  isDisabled?: boolean;
  manualRegistration?: boolean;
  screenId?: string;
}
const LayoutOptionController: React.FC<LayoutOptionControllerProps> = (
  props,
) => {
  const {
    name,
    label,
    onChangePreview,
    language,
    isDisabled,
    manualRegistration,
    screenId,
    children,
  } = props;
  const enabled = useWatch({ name: `${name}.enabled` });

  const { data, loading } = useQuery<{
    manualRegistrations: IManualRegistration[];
    manualRegistrationsCount: number;
  }>(QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATIONS, {
    variables: {
      filter: {
        screen: screenId,
      },
    },
    skip: !manualRegistration || !screenId,
    fetchPolicy: "network-only",
  });

  return (
    <div style={{ marginBottom: "2.25rem" }}>
      <Controller
        name={`${name}.enabled`}
        render={(form) => (
          <FormCheckbox
            form={form}
            label={label}
            onChangePreview={onChangePreview}
            isDisabled={isDisabled}
          />
        )}
      />
      {!children && (
        <>
          <LayoutOptionText
            name={name}
            onChangePreview={onChangePreview}
            language={language ?? "en"}
          />
          {manualRegistration && (
            <LayoutOptionRegistration
              name={name}
              manualRegistrations={data?.manualRegistrations ?? []}
              loading={loading}
            />
          )}
        </>
      )}
      {enabled && children}
    </div>
  );
};

interface FormValues extends IDigitalReceptionLayout {
  clock: {
    enabled: boolean;
    opacity: number;
  };
}

const Layout = () => {
  const methods = useForm<FormValues>({
    shouldUnregister: false,
  });

  const [language, setLanguage] = useState<string>();

  const { screenId } = useParams<{ screenId: string }>();

  const [iFrameSource, setIFrameSource] = useState<string>();

  const isFree = useCurrentHasPlan(["free"]);

  const previewRef = useRef<HTMLIFrameElement>(null);

  const { t } = useTranslation(["screens", "common"]);

  const { data: screenData, refetch } = useQuery<{
    digitalReception: IDigitalReception;
  }>(QUERY_DIGITAL_RECEPTION_SETTINGS, {
    skip: !screenId,
    variables: {
      id: screenId,
      language,
    },
    onCompleted: ({
      digitalReception: { settings, languages, language: lng },
    }) => {
      if (!settings?.layout) {
        return;
      }

      setLanguage(language && languages.includes(language) ? language : lng);

      methods.reset({
        ...settings.layout,
        clock: { ...settings.clock },
      });
    },
  });

  useEffect(() => {
    if (screenData?.digitalReception?.previewUrl && !iFrameSource) {
      setIFrameSource(
        `${screenData?.digitalReception?.previewUrl}/?preview=true`,
      );
    }
  }, [screenData]);

  const languageOptions = useMemo(
    () =>
      map(screenData?.digitalReception.languages, (value) => ({
        value,
        label: t(`languages:${value}`),
      })) ?? [],
    [screenData, t],
  );

  useEffect(() => {
    refetch({ id: screenId, language });
  }, [language, refetch, screenId]);

  const isIntegratedManualCheckinEnabled = methods.watch(
    "integratedManualCheckin.enabled",
  );

  const isIndependentManualCheckinEnabled = methods.watch(
    "independentManualCheckin.enabled",
  );

  const postMessage = useCallback(
    (message: string, targetOrigin = "*") => {
      return previewRef?.current?.contentWindow?.postMessage(
        message,
        targetOrigin,
      );
    },
    [previewRef],
  );

  useEffect(() => {
    const message = JSON.stringify({
      action: "update-language",
      data: language,
    });

    postMessage(message);
  }, [language]);

  const onChangePreview = useCallback(
    (name: string, value: any) => {
      const data = {};
      set(
        data,
        [
          "digitalReception",
          "settings",
          ...(name.includes("clock") ? [] : ["layout"]),
          ...name.split("."),
        ],
        value,
      );

      const message = JSON.stringify({
        action: "update-digitalReception",
        data,
      });

      return postMessage(message);
    },
    [postMessage],
  );

  const [onUpdate] = useMutation(UPDATE_DIGITAL_RECEPTION_SETTINGS, {
    refetchQueries: [
      {
        query: QUERY_DIGITAL_RECEPTION_SETTINGS,
        variables: { id: screenId, language },
      },
    ],
  });

  const onSubmit = useCallback(
    async (layout: FormValues) => {
      const nextLayout: IDigitalReceptionLayout = {};
      let clock: Partial<IDigitalReceptionClock> = {};

      for (const [key, value] of Object.entries(layout)) {
        const isCustom = get(value, "isCustom");

        if (key === "clock") {
          clock = {
            enabled: get(value, "enabled") || false,
            opacity: get(value, "opacity"),
          };
        }

        if (key !== "__typename" && key !== "clock") {
          nextLayout[key as keyof IDigitalReceptionLayout] = {
            enabled: get(value, "enabled") || false,
            text: get(value, "text") || "",
            registration: get(value, "registration.id"),
            isCustom: typeof isCustom === "boolean" ? isCustom : undefined,
            instruction: get(value, "instruction"),
            svg: get(value, "svg") || undefined,
          };
        }
      }

      onUpdate({
        variables: {
          input: {
            id: screenId,
            language,
            settings: {
              layout: nextLayout,
              clock,
            },
          },
        },
      })
        .then(() => {
          toast.success<string>(t("screens:screen.screenRoute.toast.updated"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [onUpdate, t, screenId, language],
  );

  return (
    <div className="container-fluid">
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <label htmlFor="roles" className="mb-2">
              {t("screens:screen.settings.layout.language")}
            </label>
            <div className="mb-4">
              <Select
                options={languageOptions}
                value={languageOptions.find(
                  (option) => option.value === language,
                )}
                onChange={(value) => {
                  setLanguage(value?.value);
                }}
                styles={reactSelectCustomStyles(false)}
              />
            </div>
            <LayoutOptionController
              name="welcomeMessage"
              label={t(`screens:screen.settings.layout.welcomeMessage`)}
              onChangePreview={onChangePreview}
              language={language}
            />
            <LayoutOptionController
              name="checkin"
              label={t(`screens:screen.settings.layout.checkin`)}
              onChangePreview={onChangePreview}
              language={language}
            />
            <LayoutOptionController
              name="integratedManualCheckin"
              label={t(
                "screens:screen.settings.layout.integratedManualCheckin",
              )}
              onChangePreview={onChangePreview}
              language={language}
              isDisabled={isIndependentManualCheckinEnabled}
              manualRegistration
              screenId={screenId}
            />
            <LayoutOptionController
              name="independentManualCheckin"
              label={t(
                "screens:screen.settings.layout.independentManualCheckin",
              )}
              onChangePreview={onChangePreview}
              language={language}
              isDisabled={isIntegratedManualCheckinEnabled}
              manualRegistration
              screenId={screenId}
            />
            {!isFree && (
              <LayoutOptionController
                name="overview"
                label={t(`screens:screen.settings.layout.overview`)}
                onChangePreview={onChangePreview}
                language={language}
              >
                {/* <LayoutOptionSvg
                  name="overview"
                  onChangePreview={onChangePreview}
                  availableSvgs={
                    screenData?.digitalReception?.settings?.layout?.overview
                      ?.availableSvgs
                  }
                /> */}
              </LayoutOptionController>
            )}
          </div>
          <div className="col-lg-4">
            <LayoutOptionController
              name="checkout"
              label={t(`screens:screen.settings.layout.checkout`)}
              onChangePreview={onChangePreview}
              language={language}
            />
            <LayoutOptionController
              name="manualCheckout"
              label={t("screens:screen.settings.layout.manualCheckout")}
              onChangePreview={onChangePreview}
              language={language}
            />
            <LayoutOptionController
              name="package"
              label={t(`screens:screen.settings.layout.package`)}
              onChangePreview={onChangePreview}
              language={language}
            >
              <Controller
                name="package.isCustom"
                render={(form) => (
                  <FormCheckbox
                    form={form}
                    label={t("screens:screen.settings.layout.isCustom")}
                  />
                )}
              />
              <LayoutOptionText
                name="package"
                onChangePreview={onChangePreview}
                language={language ?? "en"}
              />
            </LayoutOptionController>
            <LayoutOptionController
              name="food"
              label={t(`screens:screen.settings.layout.food`)}
              onChangePreview={onChangePreview}
              language={language}
            >
              <Controller
                name="food.isCustom"
                render={(form) => (
                  <FormCheckbox
                    form={form}
                    label={t("screens:screen.settings.layout.isCustom")}
                  />
                )}
              />
              <LayoutOptionText
                name="food"
                onChangePreview={onChangePreview}
                language={language ?? "en"}
              />
            </LayoutOptionController>
            {!isFree && (
              <LayoutOptionController
                name="callReception"
                label={t(`screens:screen.settings.layout.callReception`)}
                onChangePreview={onChangePreview}
                language={language}
              />
            )}

            <LayoutOptionController
              name="wayfinding"
              label={t(`screens:screen.settings.layout.wayfinding`)}
              onChangePreview={onChangePreview}
              language={language}
            >
              <LayoutOptionText
                name="wayfinding"
                onChangePreview={onChangePreview}
                language={language ?? "en"}
              />
              <label className="mb-0">
                {t("screens:screen.settings.layout.instruction")}
              </label>
              <div className="form-group my-2">
                <div className="input-group ">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      {language?.toUpperCase() || "EN"}
                    </span>
                  </div>
                  <Controller
                    name="wayfinding.instruction"
                    render={({
                      field: { name, ...rest },
                      fieldState: { error },
                    }) => (
                      <textarea
                        {...rest}
                        rows={5}
                        aria-describedby={`translate-${name}`}
                        className={clsx("form-control", {
                          "is-invalid": !!error,
                        })}
                      />
                    )}
                  />
                </div>
              </div>
              <LayoutOptionSvg
                name="wayfinding"
                onChangePreview={onChangePreview}
                availableSvgs={
                  screenData?.digitalReception?.settings?.layout?.wayfinding
                    ?.availableSvgs
                }
              />
            </LayoutOptionController>
            <LayoutOptionController
              name="wifi"
              label={t(`screens:screen.settings.layout.wifi`)}
              onChangePreview={onChangePreview}
              language={language}
            />
            <LayoutOptionController
              name="clock"
              label={t(`screens:screen.settings.layout.clock.title`)}
              onChangePreview={onChangePreview}
              language={language}
            >
              <Controller
                name="clock.opacity"
                render={({ field: { name, value, onChange, ...rest } }) => {
                  const updateValue = (
                    event: ChangeEvent<HTMLInputElement>,
                  ) => {
                    const value = parseInt(event.target.value.toString(), 10);
                    if (Number.isNaN(value)) {
                      return;
                    }

                    onChange(value);
                    onChangePreview("clock.enabled", true);
                    onChangePreview("clock.opacity", value);
                  };

                  return (
                    <div className="form-group">
                      <div className="form-inline" style={{ gap: "1rem" }}>
                        <label className="formControlRange" htmlFor={name}>
                          {t("screens:screen.settings.layout.clock.opacity")}
                        </label>
                        <div>
                          <input
                            id={name}
                            {...rest}
                            type="range"
                            min="0"
                            max="100"
                            className="form-control-range"
                            value={value}
                            onChange={updateValue}
                          />
                        </div>
                        <div>
                          <Input
                            type="number"
                            name={name}
                            id={name}
                            min="0"
                            max="100"
                            value={value}
                            onInput={updateValue}
                            onChange={updateValue}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
            </LayoutOptionController>
          </div>
          <div className="col-lg-4 mt-5">
            <div className="col-12 mb-2">
              <div
                className=" position-absolute p-0"
                style={{ zIndex: 100, width: 600 / (1920 / 1080), height: 600 }}
              />
              <iframe
                id="preview"
                ref={previewRef}
                title={`screen-${screenId}`}
                className="border border-dark"
                src={iFrameSource}
                style={{
                  width: 600 / (1920 / 1080),
                  height: 600,
                }}
                frameBorder={0}
                marginHeight={0}
                marginWidth={0}
              />
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Layout;

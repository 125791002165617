import React, { useCallback, useMemo } from "react";

import Button from "react-bootstrap/Button";

import map from "lodash/map";

import { useQuery } from "@apollo/client";
import { useRouteMatch, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Table from "../../../../components/Table";

import { QUERY_MESSAGE_TEMPLATES } from "../../../../config/graphql/query";

import { useClientId } from "../hooks/useClient";

const TableRow = ({
  item,
  index,
}: {
  item: IMessageTemplate;
  index: number;
}) => {
  const { url } = useRouteMatch();

  const { t } = useTranslation(["client", "common"]);

  const { id, title, template } = item;

  return (
    <>
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{title}</td>
        <td>{template}</td>
        <td>
          <div className="d-flex">
            <Link to={`${url}/${id || template}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
    </>
  );
};

const MessageTemplates = React.memo(() => {
  const clientId = useClientId();

  const { t } = useTranslation(["client", "common"]);

  const { data } = useQuery<{
    messageTemplates: IMessageTemplate[];
    defaultMessageTemplates: Partial<IMessageTemplate>[];
  }>(QUERY_MESSAGE_TEMPLATES, {
    fetchPolicy: "network-only",
    skip: !clientId,
    context: {
      headers: {
        ClientId: clientId,
      },
    },
  });

  const templates = useMemo(
    () =>
      data?.defaultMessageTemplates?.map((defaultTemplate) => {
        const customTemplate = data?.messageTemplates?.find(
          (msg) => msg.template === defaultTemplate.template,
        );

        return customTemplate || defaultTemplate;
      }),
    [data],
  );

  const renderItem = useCallback(
    (item: IMessageTemplate, index: number) => (
      <TableRow key={item.template} item={item} index={index} />
    ),
    [],
  );

  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t("client:client.sendgrid.template.th.title")}</th>
            <th scope="col">
              {t("client:client.sendgrid.template.th.template")}
            </th>
            <th scope="col">{t("client:client.tokens.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{map(templates, renderItem)}</tbody>
      </Table>
    </>
  );
});

export default MessageTemplates;

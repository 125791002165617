import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import MsiDevice from "./MsiDevice";
import MsiDeviceList from "./MsiDeviceList";

const MsiDeviceRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <MsiDeviceList />
      </Route>
      <Route path={`${path}/:entityId`}>
        <MsiDevice />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default MsiDeviceRouter;

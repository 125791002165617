export const getDomain = () => {
  const { hostname } = window.location;
  const parts = hostname.split(".");

  if (parts.length > 2) {
    return parts.slice(-2).join(".");
  }

  return hostname;
};

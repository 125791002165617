import React, { useCallback, useEffect, useMemo, useState } from "react";

import * as yup from "yup";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import clsx from "clsx";
import Select from "react-select";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useParams, useHistory, Link } from "react-router-dom";

import {
  MUTATION_CREATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
  MUTATION_UPDATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
} from "../../../../../../config/graphql/mutation";

import {
  QUERY_DIGITAL_RECEPTION_ACTIVE_LANGUAGES,
  QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
  QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
  QUERY_DIGITAL_RECEPTION_SETTINGS,
} from "../../../../../../config/graphql/query";

import { reactSelectCustomStyles } from "../../../../../Employees/Employee/Information";
import Input from "../../../../../../components/Input";

const fieldTypeOptions: ManualRegistrationFieldType[] = [
  "Text",
  "Email",
  "Phone",
  "Number",
  "EmployeeList",
  "Dropdown",
  "Checkbox",
  "RadioButtons",
];

interface FieldValues {
  type: ManualRegistrationFieldType;
  label: string;
  description?: string;
  required: boolean;
  manualCheckoutIdentifier?: boolean;
  dropdownOptions?: string[];
  checkbox?: {
    description?: { title?: string; text?: string };
    label: string;
  };
  radioButtons?: string[];
  hasOtherRadioButton?: boolean;
}

const removeTypename = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeTypename);
  }

  if (typeof obj === "object" && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([key]) => key !== "__typename")
        .map(([key, value]) => [key, removeTypename(value)]),
    );
  }
  return obj;
};

const TextController = (props: {
  name: string;
  language: string | undefined;
  isTextarea?: boolean;
}) => {
  const { name, language, isTextarea } = props;

  const { t } = useTranslation(["screens"]);

  return (
    <Controller
      name={name}
      render={({ field: { onChange, ...props }, fieldState: { error } }) => (
        <div className="form-group">
          <label htmlFor="description">
            {t(`manualRegistration:manualRegistrationField.form.${name}`)}
          </label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                {language?.toUpperCase() ?? "en"}
              </span>
            </div>
            {!isTextarea ? (
              <input
                onChange={(value) => {
                  onChange(value);
                }}
                {...props}
                className={clsx("form-control", {
                  "is-invalid": !!error,
                })}
              />
            ) : (
              <textarea
                onChange={(value) => {
                  onChange(value);
                }}
                {...props}
                rows={5}
                aria-describedby={`translate-${name}`}
                className={clsx("form-control", {
                  "is-invalid": !!error,
                })}
              />
            )}
            {!!error && (
              <div className="invalid-feedback">{error?.message}</div>
            )}
          </div>
        </div>
      )}
    />
  );
};

const ManualRegistrationField = React.memo(() => {
  const history = useHistory();

  const { t } = useTranslation(["manualRegistration", "common"]);

  const [language, setLanguage] = useState<string>();

  const { screenId, manualRegistrationId, manualRegistrationFieldId } =
    useParams<{
      screenId?: string;
      manualRegistrationId?: string;
      manualRegistrationFieldId?: string;
    }>();

  const schema = useMemo(() => {
    return yup.object().shape({
      type: yup
        .string()
        .oneOf(fieldTypeOptions)
        .required(t("manualRegistration:manualRegistrationField.yup.type")),
      label: yup
        .string()
        .required(t("manualRegistration:manualRegistrationField.yup.label")),
      dropdownOptions: yup
        .array()
        .of(
          yup
            .string()
            .trim()
            .min(
              1,
              t(
                "manualRegistration:manualRegistrationField.yup.dropdownOptions.empty",
              ),
            ),
        )
        .when("type", {
          is: "Dropdown",
          then: (schema) =>
            schema.min(
              1,
              t(
                "manualRegistration:manualRegistrationField.yup.dropdownOptions.min",
              ),
            ),
          otherwise: (schema) => schema.notRequired().nullable().default([]),
        }),
      checkbox: yup.mixed().when("type", {
        is: "Checkbox",
        then: () =>
          yup.object().shape({
            label: yup
              .string()
              .nullable()
              .required(
                t(
                  "manualRegistration:manualRegistrationField.yup.checkbox.label",
                ),
              ),
          }),
        otherwise: () => yup.mixed().notRequired(),
      }),
      radioButtons: yup
        .array()
        .of(
          yup
            .string()
            .trim()
            .min(
              1,
              t(
                "manualRegistration:manualRegistrationField.yup.radioButtons.empty",
              ),
            ),
        )
        .when("type", {
          is: "RadioButtons",
          then: (schema) =>
            schema.min(
              1,
              t(
                "manualRegistration:manualRegistrationField.yup.radioButtons.min",
              ),
            ),
          otherwise: (schema) => schema.notRequired().nullable().default([]),
        }),
    });
  }, [t]);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      type: "Text",
      label: "",
      description: undefined,
      required: true,
      manualCheckoutIdentifier: undefined,
      dropdownOptions: undefined,
      checkbox: { label: undefined },
      radioButtons: undefined,
      hasOtherRadioButton: undefined,
    },
  });

  const { data: screenData } = useQuery<{
    digitalReception: IDigitalReception;
  }>(QUERY_DIGITAL_RECEPTION_ACTIVE_LANGUAGES, {
    skip: !screenId,
    variables: {
      id: screenId,
    },
    onCompleted: ({ digitalReception }) => {
      setLanguage(
        language && digitalReception.languages.includes(language)
          ? language
          : digitalReception.languages[0],
      );
    },
  });

  const languageOptions = useMemo(
    () =>
      map(screenData?.digitalReception.languages, (value) => ({
        value,
        label: t(`languages:${value}`),
      })) ?? [],
    [screenData, t],
  );

  const {
    data: manualRegistrationFieldData,
    loading,
    refetch,
  } = useQuery<{
    manualRegistrationField: IManualRegistrationField;
  }>(QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD, {
    variables: {
      id: manualRegistrationFieldId,
      language,
    },
    skip: !manualRegistrationFieldId || !language,
    onError: () => {
      history.replace(
        `/digital-receptions/${screenId}/settings/manual-registrations/${manualRegistrationId}`,
      );
    },
  });

  useEffect(() => {
    if (!!manualRegistrationFieldId && !!language) {
      refetch({ id: manualRegistrationFieldId, language });
    }
  }, [language, refetch, manualRegistrationFieldId]);

  useEffect(() => {
    if (manualRegistrationFieldData?.manualRegistrationField) {
      methods.reset({
        ...manualRegistrationFieldData?.manualRegistrationField,
        dropdownOptions:
          manualRegistrationFieldData?.manualRegistrationField.dropdownOptions?.map(
            (option) => option.label,
          ),
        radioButtons:
          manualRegistrationFieldData?.manualRegistrationField.radioButtons?.buttons?.map(
            (radioButton) => radioButton.label,
          ),
        hasOtherRadioButton:
          manualRegistrationFieldData?.manualRegistrationField.radioButtons
            ?.hasOther,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualRegistrationFieldData]);

  const type = methods.watch("type");
  const isDropdown = type === "Dropdown";
  const isCheckbox = type === "Checkbox";
  const isRadioButtons = type === "RadioButtons";

  const dropDownOptions = methods.watch("dropdownOptions");
  const radio = methods.watch("radioButtons");

  const dropdownError =
    dropDownOptions?.length === 0 &&
    methods.formState.errors.dropdownOptions?.message;
  const radioButtonsError =
    radio?.length === 0 && methods.formState.errors.radioButtons?.message;

  useEffect(() => {
    if (!isDropdown) {
      methods.setValue("dropdownOptions", []);
    }
    if (!isRadioButtons) {
      methods.setValue("radioButtons", []);
    }
  }, [isDropdown, isRadioButtons, methods]);

  const {
    append: appendDropdownOption,
    remove: removeDropdownOption,
    fields: dropdownOptions,
    // @ts-ignore
  } = useFieldArray<FieldValues, "dropdownOptions">({
    control: methods.control,
    name: "dropdownOptions",
  });

  const {
    append: appendRadioButton,
    remove: removeRadioButton,
    fields: radioButtons,
    // @ts-ignore
  } = useFieldArray<FieldValues, "dropdownOptions">({
    control: methods.control,
    name: "radioButtons",
  });

  const renderOptions = useCallback(
    (type: "dropdown" | "radio", field: any, index: number) => {
      return (
        <div key={field.id}>
          <div className="d-flex mb-3">
            <div className="flex-grow-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    {language?.toUpperCase() ?? "en"}
                  </span>
                </div>
                <Input
                  {...methods.register(
                    `${
                      type === "dropdown" ? "dropdownOptions" : "radioButtons"
                    }.${index}`,
                  )}
                  className="form-control"
                  defaultValue={field}
                />
              </div>
            </div>
            <button
              onClick={() => {
                if (type === "dropdown") {
                  removeDropdownOption(index);
                } else {
                  removeRadioButton(index);
                }
              }}
              className="btn btn-danger ml-3 align-self-start"
            >
              {t("common:remove")}
            </button>
          </div>
        </div>
      );
    },
    [language, removeDropdownOption, removeRadioButton, methods, t],
  );

  const [onUpdate] = useMutation(
    MUTATION_UPDATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
          variables: { id: manualRegistrationFieldId, language },
        },
        {
          query: QUERY_DIGITAL_RECEPTION_SETTINGS,
          variables: { id: screenId, language },
        },
      ],
    },
  );
  const [onCreate] = useMutation(
    MUTATION_CREATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
          variables: { id: manualRegistrationId, language },
        },
        {
          query: QUERY_DIGITAL_RECEPTION_SETTINGS,
          variables: { id: screenId, language },
        },
      ],
    },
  );

  const fieldSelectOptions = useMemo(
    () =>
      map(fieldTypeOptions, (type) => ({
        value: type,
        label: type,
      })),
    [],
  );

  const onSubmit = async (values: FieldValues) => {
    const {
      type,
      label,
      description,
      required,
      manualCheckoutIdentifier,
      dropdownOptions,
      checkbox,
      radioButtons,
      hasOtherRadioButton,
    } = values;

    const input = {
      type,
      label,
      ...(typeof description === "string" && { description }),
      required,
      manualCheckoutIdentifier,
      ...(isDropdown && { dropdownOptions }),
      ...(isCheckbox && { checkbox: removeTypename(checkbox) }),
      ...(isRadioButtons && { radioButtons, hasOtherRadioButton }),
    };

    if (!!manualRegistrationFieldId) {
      return onUpdate({
        variables: {
          input: {
            id: manualRegistrationFieldId,
            language,
            ...input,
          },
        },
      })
        .then(() => {
          toast.success<string>(
            t("manualRegistration:manualRegistrationField.toast.updated"),
          );

          // history.replace(
          //   `/digital-receptions/${screenId}/settings/manual-registrations/${manualRegistrationId}`,
          // );
        })
        .catch((error) => {
          toast.error<string>(
            t(
              error?.networkError?.result?.errors?.[0]?.message ??
                error?.message,
            ),
          );
        });
    }

    return onCreate({
      variables: {
        input: {
          manualRegistration: manualRegistrationId,
          screen: screenId,
          language,
          ...input,
        },
      },
    })
      .then(() => {
        toast.success<string>(
          t("manualRegistration:manualRegistrationField.toast.created"),
        );

        history.replace(
          `/digital-receptions/${screenId}/settings/manual-registrations/${manualRegistrationId}`,
        );
      })
      .catch((error) => {
        toast.error<string>(
          t(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          ),
        );
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/digital-receptions">
              {t("screens:screensRouter.nav.screens")}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/digital-receptions/${screenId}`}>
              {t("screens:screen.screenRoute.nav.screen")}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/digital-receptions/${screenId}/settings`}>
              {t("screens:screen.screenRoute.nav.settings")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link
              to={`/digital-receptions/${screenId}/settings/manual-registrations`}
            >
              {t("screens:screen.screenRoute.nav.manualRegistrations")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link
              to={`/digital-receptions/${screenId}/settings/manual-registrations/${manualRegistrationId}`}
            >
              {t("screens:screen.screenRoute.nav.manualRegistration")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("screens:screen.screenRoute.nav.manualRegistrationField")}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <label htmlFor="roles" className="mb-2">
                {t("screens:screen.settings.layout.language")}
              </label>
              <div className="mb-4">
                <Select
                  options={languageOptions}
                  value={languageOptions.find(
                    (option) => option.value === language,
                  )}
                  onChange={(value) => {
                    setLanguage(value?.value);
                  }}
                  styles={reactSelectCustomStyles(false)}
                />
              </div>
              <Controller
                name="type"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <div className="form-group">
                      <label htmlFor="type">
                        {t(
                          "manualRegistration:manualRegistrationField.form.type",
                        )}
                      </label>
                      <Select
                        options={fieldSelectOptions}
                        onChange={(nextValue) => {
                          const nextField = get(nextValue, "value");
                          onChange(nextField);
                        }}
                        value={find(fieldSelectOptions, { value })}
                        className={clsx({
                          "is-invalid": !!error,
                        })}
                        isDisabled={
                          !!manualRegistrationFieldData?.manualRegistrationField
                            .name
                        }
                      />
                      {!!error && (
                        <div className="invalid-feedback">{error.message}</div>
                      )}
                    </div>
                  );
                }}
              />
              {isDropdown && (
                <>
                  {dropdownError && (
                    <p className="text-danger">{dropdownError}</p>
                  )}
                  {dropdownOptions.map((option, index) => {
                    return renderOptions("dropdown", option, index);
                  })}
                  <button
                    className="btn btn-secondary mb-2"
                    onClick={(e) => {
                      e.preventDefault();

                      appendDropdownOption("");
                    }}
                  >
                    {t(
                      "manualRegistration:manualRegistrationField.form.button.addDropdownOption",
                    )}
                  </button>
                </>
              )}
              {isCheckbox && (
                <>
                  <TextController name="checkbox.label" language={language} />
                  <TextController
                    name="checkbox.description.title"
                    language={language}
                  />
                  <TextController
                    name="checkbox.description.text"
                    language={language}
                    isTextarea
                  />
                </>
              )}
              {isRadioButtons && (
                <>
                  <Controller
                    name="hasOtherRadioButton"
                    render={({ field: { value, ...rest } }) => {
                      return (
                        <div className="form-check mb-3">
                          <input
                            {...rest}
                            type="checkbox"
                            className="form-check-input"
                            checked={value === true}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="hasOtherRadioButton"
                          >
                            {t(
                              "manualRegistration:manualRegistrationField.form.hasOtherRadioButton",
                            )}
                          </label>
                        </div>
                      );
                    }}
                  />
                  {radioButtonsError && (
                    <p className="text-danger">{radioButtonsError}</p>
                  )}
                  {radioButtons.map((radioButton, index) => {
                    return renderOptions("radio", radioButton, index);
                  })}
                  <button
                    className="btn btn-secondary mb-2"
                    onClick={(e) => {
                      e.preventDefault();

                      appendRadioButton("");
                    }}
                  >
                    {t(
                      "manualRegistration:manualRegistrationField.form.button.addRadioButton",
                    )}
                  </button>
                </>
              )}
              <Controller
                name="manualCheckoutIdentifier"
                render={({ field: { value, name, onChange, ...rest } }) => {
                  return (
                    <div className="form-check my-3">
                      <input
                        id={name}
                        {...rest}
                        type="checkbox"
                        className="form-check-input"
                        checked={value === true}
                        onChange={() => onChange(!value)}
                      />
                      <label className="form-check-label" htmlFor={name}>
                        {t(
                          "manualRegistration:manualRegistrationField.form.manualCheckoutIdentifier",
                        )}
                      </label>
                    </div>
                  );
                }}
              />
              <TextController name="label" language={language} />
              <TextController name="description" language={language} />
              <Controller
                name="required"
                render={({ field: { value, name, onChange, ...rest } }) => {
                  return (
                    <div className="form-check my-3">
                      <input
                        id={name}
                        {...rest}
                        type="checkbox"
                        className="form-check-input"
                        checked={value === true}
                        onChange={() => onChange(!value)}
                      />
                      <label className="form-check-label" htmlFor={name}>
                        {t(
                          "manualRegistration:manualRegistrationField.form.required",
                        )}
                      </label>
                    </div>
                  );
                }}
              />
              <button
                type="submit"
                className="btn btn-primary mt-3"
                disabled={loading}
              >
                {t(
                  `manualRegistration:manualRegistrationField.form.button.${
                    !manualRegistrationFieldId ? "create" : "update"
                  }`,
                )}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default ManualRegistrationField;

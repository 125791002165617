import { gql } from "@apollo/client";

export const FRAGMENT_FILE = gql`
  fragment FragmentFile on File {
    id
    filename
    path
    absolutePath
  }
`;

export const FRAGMENT_ENTITY_BASIC = gql`
  fragment FragmentEntityBasic on Entity {
    id
    type
    title
    metadata
    propertiesCount
    ... on Building {
      address
      city
      zipCode
      country
      entitiesCount
      logo {
        ...FragmentFile
      }
      entities {
        id
        type
      }
    }

    ... on Space {
      geoJSON
      parent {
        id
        type
        title
      }
    }
  }
  ${FRAGMENT_FILE}
`;

export const FRAGMENT_ENTITY = gql`
  fragment FragmentEntity on Entity {
    id
    type
    title
    metadata
    propertiesCount
    properties {
      id
      ... on Company {
        type
        title
      }
    }
    ... on Building {
      address
      city
      zipCode
      country
      entitiesCount
      logo {
        ...FragmentFile
      }
      entities {
        id
        type
      }
    }
    ... on Floor {
      entitiesCount
      plan {
        id
        path
        absolutePath
        filename
      }
      parent {
        id
        type
        title
      }
      entities {
        id
        type
        ... on Space {
          geoJSON
        }
      }
    }
    ... on Sector {
      entitiesCount
      parent {
        id
        type
        title
      }
    }
    ... on Space {
      geoJSON
      parent {
        id
        type
        title
      }
    }
  }
  ${FRAGMENT_FILE}
`;

export const FRAGMENT_ENTITY_PARENT = gql`
  fragment FragmentEntityParent on Entity {
    ... on Floor {
      parent {
        id
        type
      }
    }
    ... on Sector {
      parent {
        id
        type
      }
    }
    ... on Space {
      parent {
        id
        type
      }
    }
  }
`;

export const FRAGMENT_ENTITY_PARENTS = gql`
  fragment FragmentEntityParents on Entity {
    ... on Floor {
      parents {
        id
        title
      }
    }
    ... on Sector {
      parents {
        id
        title
      }
    }
    ... on Space {
      parents {
        id
        title
      }
    }
  }
`;

export const FRAGMENT_ENTITY_BUILDING = gql`
  fragment FragmentEntityBuilding on Entity {
    id
    type
    ... on Building {
      title
      address
      city
      zipCode
      country
    }
  }
`;

export const FRAGMENT_PROPERTY = gql`
  fragment FragmentProperty on Property {
    id
    ... on Company {
      type
      createdAt
      updatedAt
      title
      description
      address
      city
      zipCode
      country
      shortenURL
      postbox
      phone
      fax
      website
      email
      facebook
      twitter
      linkedIn
      emailLanguage
    }
    ... on Doctor {
      type
      title
    }
    ... on Deceased {
      id
      title
      gender
    }
  }
`;

export const FRAGMENT_COMPANY = gql`
  fragment FragmentCompany on Company {
    id
    type
    createdAt
    updatedAt
    title
    description
    address
    city
    zipCode
    country
    shortenURL
    postbox
    phone
    fax
    website
    email
    facebook
    twitter
    linkedIn
    emailLanguage
  }
`;

export const FRAGMENT_CLIENT = gql`
  fragment FragmentClient on Client {
    id
    title
    email
    domain
    description
    plan
  }
`;

export const FRAGMENT_CLIENT_TOKEN = gql`
  fragment FragmentClientToken on ClientToken {
    id
    title
    token
    development
    ip
    client {
      id
      title
    }
  }
`;

export const FRAGMENT_USER = gql`
  fragment FragmentUser on User {
    id
    createdAt
    firstName
    lastName
    email
    language
    roles
    emailVerified
    companies {
      id
      title
    }
  }
`;

export const FRAGMENT_NAMEBOARD = gql`
  fragment FragmentNameboard on Nameboard {
    id
    title
    template
  }
`;

export const FRAGMENT_DIGITAL_RECEPTION = gql`
  fragment FragmentDigitalReception on DigitalReception {
    id
    title
    template
  }
`;

export const FRAGMENT_NAMEBOARD_SETTINGS = gql`
  fragment FragmentNameboardSettings on NameboardSettings {
    layout
    plugins {
      feed {
        url
      }
      twitter {
        username
      }
      externalVisitors {
        id
        url
      }
    }
    hardware {
      scannerPosition
      dispensers {
        id
        name
      }
      validators {
        id
        name
      }
    }
    support {
      email
      phone
    }
  }
`;

export const FRAGMENT_VISIONECT = gql`
  fragment FragmentVisionect on Visionect {
    id
    title
    template
    rotation
    device {
      id
      deviceId
      name
      deviceData {
        Uuid
        SessionId
        Displays {
          Id
          Rotation
        }
        Options {
          Name
        }
        Status {
          Battery
          ConnectivityUsed
        }
        State
      }
    }
  }
`;

export const FRAGMENT_ERROR = gql`
  fragment FragmentError on ErrorLog {
    id
    level
    user {
      id
      firstName
      lastName
    }
    method
    endpoint
    payload
    ip
    rawHeaders
    errorMessage
    errorStack
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_ENTITY_DESK = gql`
  fragment FragmentEntityDesk on EntityDesk {
    id
    email
    entity {
      id
      type
    }
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_LOG = gql`
  fragment FragmentLog on Log {
    id
    level
    user {
      id
      firstName
      lastName
    }
    method
    message
    endpoint
    payload
    ip
    rawHeaders
    responseDuration
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_OUTLOOK_SUBSCRIPTION = gql`
  fragment FragmentOutlookSubscription on OutlookSubscription {
    id
    subscriptionId
    title
    description
    email
    createdAt
    updatedAt
    homeTenantId
    resource
    changeType
    clientState
    expirationDateTime
    building {
      id
      title
    }
    client {
      id
      title
    }
  }
`;

export const FRAGMENT_ACTIVE_DIRECTORY = gql`
  fragment FragmentActiveDirectory on ActiveDirectory {
    id
    homeAccountId
    email
    name
    permissions
    title
    description
    client {
      id
      title
    }
  }
`;

export const FRAGMENT_ACTIVE_DIRECTORY_SYNC = gql`
  fragment FragmentActiveDirectorySync on ActiveDirectorySync {
    id
    title
    company {
      id
      title
    }
    activeDirectory {
      id
      title
      email
    }
    groupId
    group {
      id
      displayName
    }
    memberGroups {
      id
      title
    }
    settings {
      syncFields {
        phone {
          enabled
          type
        }
        skipAccess
      }
    }
  }
`;

export const FRAGMENT_TWILIO_CLIENT = gql`
  fragment FragmentTwilioClient on TwilioClient {
    id
    createdAt
    updatedAt
    title
    description
    accountSid
    authToken
    apiKey
    apiSecret
    applicationId
    callerId
    messagingServiceSid
    # client {
    #   id
    #   title
    # }
  }
`;

export const FRAGMENT_CLIENT_SENDGRID = gql`
  fragment FragmentClientSendGrid on SendGridClient {
    id
    createdAt
    updatedAt
    title
    description
    apiKey
    senderEmail
    senderName
  }
`;

export const FRAGMENT_CLIENT_SENDGRID_TEMPLATE = gql`
  fragment FragmentClientSendGridTemplate on EmailTemplate {
    id
    createdAt
    updatedAt
    title
    template
    templateId
  }
`;

export const FRAGMENT_MESSAGE_TEMPLATE = gql`
  fragment FragmentMessageTemplate on MessageTemplate {
    id
    title
    message
    template
    createdAt
    updatedAt
    client {
      id
      title
    }
  }
`;

export const FRAGMENT_DEFAULT_MESSAGE_TEMPLATE = gql`
  fragment FragmentDefaultMessageTemplate on DefaultMessageTemplate {
    title
    message
    template
  }
`;

export const FRAGMENT_SCREEN = gql`
  fragment FragmentScreen on Screen {
    id
    title
    template
    metadata
    playerUrl
    previewUrl
    client {
      id
    }
    ... on Nameboard {
      settings {
        ...FragmentNameboardSettings
      }
    }
    ... on FloorInfo {
      floors {
        sort
        floor {
          id
          title
          ... on Floor {
            parent {
              title
            }
          }
        }
      }
    }
    ... on Postbox {
      buildings {
        id
        title
      }
    }
    ... on DigitalReception {
      code
    }
  }
  ${FRAGMENT_NAMEBOARD_SETTINGS}
`;

export const FRAGMENT_OWNER = gql`
  fragment FragmentAccessEventOwner on AccessEventOwner {
    ... on Visitor {
      id
      type
      email
      fullName
      phone
      photo {
        absolutePath
      }
    }
    ... on Employee {
      id
      type
      email
      fullName
      phone
      employeeCompany: company {
        title
      }
      photo {
        absolutePath
      }
    }
    ... on MeetingAttendee {
      id
      email
      fullName
      phone
      member {
        type
        email
        fullName
      }
      photo {
        absolutePath
      }
    }
    ... on MmmrDriver {
      id
      fullName
      badge
      company
      phone
    }
    ... on MmmrVisitor {
      id
      fullName
      email
      company
      badgeNumber
      function
      isMaintenance
    }
    ... on MmmrAdditionalVisitor {
      id
      fullName
    }
    ... on Guest {
      fields {
        name
        value
      }
      photo {
        absolutePath
      }
    }
  }
`;

export const FRAGMENT_PREFERENCE_CHANNEL = gql`
  fragment FragmentPreferenceChannels on MemberGroupPreferenceChannels {
    sms
    call
    ping
    videoCall
    teamsCall
    teamsMessage
  }
`;

export const FRAGMENT_PREFERENCE_AUTO_CHANNEL = gql`
  fragment FragmentPreferenceAutoChannels on MemberGroupPreferenceAutoChannels {
    email
    sms
    call
    ping
    teamsMessage
  }
`;

export const FRAGMENT_PREFERENCE = gql`
  fragment FragmentPreference on MemberGroupPreference {
    enabled
    channel {
      ...FragmentPreferenceChannels
    }
    autoChannel {
      ...FragmentPreferenceAutoChannels
    }
  }
  ${FRAGMENT_PREFERENCE_CHANNEL}
  ${FRAGMENT_PREFERENCE_AUTO_CHANNEL}
`;

export const FRAGMENT_MEMBER_GROUP_PREFERENCES = gql`
  fragment FragmentMemberGroupPreferences on MemberGroupPreferences {
    visitor {
      ...FragmentPreference
    }
    meetingAttendee {
      ...FragmentPreference
    }
    foodDelivery {
      ...FragmentPreference
    }
    packageDelivery {
      ...FragmentPreference
    }
  }
  ${FRAGMENT_PREFERENCE}
`;

export const FRAGMENT_CLIENT_COMPANY_PREFERENCES = gql`
  fragment FragmentClientCompanyPreferences on ClientCompanyPreferences {
    employee {
      ...FragmentMemberGroupPreferences
    }
  }
  ${FRAGMENT_MEMBER_GROUP_PREFERENCES}
`;

export const FRAGMENT_CLIENT_MEMBER_ACCESS_PREFERENCES = gql`
  fragment FragmentClientMemberAccessPreferences on ClientMemberAccessPreferences {
    employee {
      enabled
    }
  }
`;

export const FRAGMENT_MEETING_PREFERENCES = gql`
  fragment FragmentMeetingPreferences on MeetingPreferences {
    reminder {
      enabled
    }
    reminderMinutesBefore
    hostCancellation {
      enabled
    }
    visitorCancellation {
      enabled
    }
  }
`;

export const FRAGMENT_CHECK_PREFERENCES = gql`
  fragment FragmentCheckPreferences on CheckPreferences {
    employee {
      enabled
    }
    visitor {
      enabled
    }
    meetingAttendee {
      enabled
    }
  }
`;

export const FRAGMENT_CLIENT_EMAIL_PREFERENCES = gql`
  fragment FragmentClientEmailPreferences on ClientEmailPreferences {
    meeting {
      ...FragmentMeetingPreferences
    }
    checkin {
      ...FragmentCheckPreferences
    }
    checkout {
      ...FragmentCheckPreferences
    }
  }
  ${FRAGMENT_MEETING_PREFERENCES}
  ${FRAGMENT_CHECK_PREFERENCES}
`;

export const FRAGMENT_CLIENT_AUTO_CHECKOUT_PREFERENCES = gql`
  fragment FragmentClientAutoCheckoutPreferences on ClientAutoCheckoutPreferences {
    enabled
    time
  }
`;

export const FRAGMENT_CLIENT_PREFERENCES = gql`
  fragment FragmentClientPreferences on ClientPreferences {
    company {
      ...FragmentClientCompanyPreferences
    }
    memberAccess {
      ...FragmentClientMemberAccessPreferences
    }
    email {
      ...FragmentClientEmailPreferences
    }
    autoCheckout {
      ...FragmentClientAutoCheckoutPreferences
    }
    photoCapture {
      enabled
    }
  }
  ${FRAGMENT_CLIENT_COMPANY_PREFERENCES}
  ${FRAGMENT_CLIENT_MEMBER_ACCESS_PREFERENCES}
  ${FRAGMENT_CLIENT_EMAIL_PREFERENCES}
  ${FRAGMENT_CLIENT_AUTO_CHECKOUT_PREFERENCES}
`;

export const FRAGMENT_CURRENT_USER = gql`
  fragment FragmentCurrentUser on User {
    id
    firstName
    lastName
    roles
    email
    enabled2fa
    language
    client {
      id
      code
      plan
      title
      email
      domain
      preferences {
        ...FragmentClientPreferences
      }
    }
  }
  ${FRAGMENT_CLIENT_PREFERENCES}
`;

export const FRAGMENT_MEMBER_GROUP = gql`
  fragment FragmentMemberGroup on MemberGroup {
    id
    title
    preferences {
      ...FragmentMemberGroupPreferences
    }
  }
  ${FRAGMENT_MEMBER_GROUP_PREFERENCES}
`;

export const FRAGMENT_EMPLOYEE = gql`
  fragment FragmentEmployee on Employee {
    id
    fullName
    email
    phone
    company {
      id
      title
    }
    access {
      id
      fallbackCode
      validFrom
      validUntil
      file {
        absolutePath
      }
    }
    externalSource {
      id
      source
    }
    availablePreferences {
      ...FragmentMemberGroupPreferences
    }
    groups {
      ...FragmentMemberGroup
    }
    photo {
      absolutePath
    }
  }
  ${FRAGMENT_MEMBER_GROUP}
  ${FRAGMENT_MEMBER_GROUP_PREFERENCES}
`;

export const FRAGMENT_VISITOR = gql`
  fragment FragmentVisitor on Visitor {
    id
    fullName
    email
    phone
    access {
      id
      fallbackCode
      validFrom
      validUntil
      file {
        absolutePath
      }
    }
    photo {
      absolutePath
    }
  }
`;

export const FRAGMENT_MEETING = gql`
  fragment FragmentMeeting on Meeting {
    id
    subject
    content
    dtstart
    dtend
    externalId
    createdAt
    updatedAt
    organizer {
      member {
        id
      }
      id
      fullName
      email
      access {
        id
        fallbackCode
        file {
          absolutePath
        }
      }
      photo {
        absolutePath
      }
    }
    attendees {
      member {
        id
      }
      id
      fullName
      email
      phone
      access {
        id
        fallbackCode
        file {
          absolutePath
        }
      }
      photo {
        absolutePath
      }
    }
  }
`;

export const FRAGMENT_SCHEDULE = gql`
  fragment FragmentSchedule on Schedule {
    id

    weekdays

    startDate
    startTime

    endDate
    endTime

    disabled
  }
`;

export const FRAGMENT_SCREEN_SCHEDULE = gql`
  fragment FragmentScreenSchedule on ScreenSchedule {
    id
    type
    schedule {
      ...FragmentSchedule
    }
  }
  ${FRAGMENT_SCHEDULE}
`;

export const FRAGMENT_WAYFINDING_SCREEN = gql`
  fragment FragmentWayfindingScreen on WayfindingScreen {
    id
    title
    template
    metadata
  }
`;

export const FRAGMENT_ENTRANCE_AGREEMENT_FILE = gql`
  fragment FragmentEntranceAgreementFile on EntranceAgreementFile {
    id

    title
    text

    disabled
    approvalRequired

    file {
      ...FragmentFile
    }

    validFrom
    validUntil
  }
  ${FRAGMENT_FILE}
`;

export const FRAGMENT_ENTRANCE_AGREEMENT = gql`
  fragment FragmentEntranceAgreement on EntranceAgreement {
    id
    title

    disabled
    showFirst

    files {
      ...FragmentEntranceAgreementFile
    }

    validFrom
    validUntil
  }
  ${FRAGMENT_ENTRANCE_AGREEMENT_FILE}
`;

export const MINEW_DEVICE_FIELDS = gql`
  fragment MinewDeviceFields on MinewDevice {
    mac
    name
    storeId
    productId
  }
`;

export const FRAGMENT_DIGITAL_RECEPTION_LAYOUT_OPTION = gql`
  fragment FragmentDigitalReceptionLayoutOption on DigitalReceptionLayoutOption {
    enabled
    text
    registration {
      id
      title
      fields {
        label
      }
    }
    isCustom
    instruction
    svg
    availableSvgs
  }
`;

export const FRAGMENT_DIGITAL_RECEPTION_LAYOUT = gql`
  fragment FragmentDigitalReceptionLayout on DigitalReceptionLayout {
    welcomeMessage {
      ...FragmentDigitalReceptionLayoutOption
    }
    checkin {
      ...FragmentDigitalReceptionLayoutOption
    }
    integratedManualCheckin {
      ...FragmentDigitalReceptionLayoutOption
    }
    independentManualCheckin {
      ...FragmentDigitalReceptionLayoutOption
    }
    checkout {
      ...FragmentDigitalReceptionLayoutOption
    }
    manualCheckout {
      ...FragmentDigitalReceptionLayoutOption
    }
    package {
      ...FragmentDigitalReceptionLayoutOption
    }
    food {
      ...FragmentDigitalReceptionLayoutOption
    }
    callReception {
      ...FragmentDigitalReceptionLayoutOption
    }
    overview {
      ...FragmentDigitalReceptionLayoutOption
    }
    wifi {
      ...FragmentDigitalReceptionLayoutOption
    }
    wayfinding {
      ...FragmentDigitalReceptionLayoutOption
    }
  }
  ${FRAGMENT_DIGITAL_RECEPTION_LAYOUT_OPTION}
`;

export const FRAGMENT_DIGITAL_RECEPTION_SETTINGS = gql`
  fragment FragmentDigitalReceptionSettings on DigitalReceptionSettings {
    support {
      phone
      email
    }
    layout {
      ...FragmentDigitalReceptionLayout
    }
    receptionistPhone {
      primary
      backup
    }
    animations {
      enabled
    }
    printing {
      enabled
      isAirPrinting
      isHorizontal
    }
    dispenser {
      id
      socketUrl
    }
    deliveryReceiver {
      fullName
      email
      phone
    }
    overview {
      code
      fields
    }
    wifi {
      securityType
      ssid
      hiddenSsid
      password
    }
    clock {
      enabled
      opacity
    }
    photoCapture {
      enabled
      employee {
        enabled
        required
      }
      visitor {
        enabled
        required
      }
      meetingAttendee {
        enabled
        required
      }
    }
  }
  ${FRAGMENT_DIGITAL_RECEPTION_LAYOUT}
`;

export const FRAGMENT_MANUAL_REGISTRATION_FIELD = gql`
  fragment FragmentManualRegistrationField on ManualRegistrationField {
    id
    code
    name
    type
    label
    description
    required
    manualCheckoutIdentifier
    dropdownOptions {
      label
      value
    }
    checkbox {
      description {
        title
        text
      }
      label
    }
    radioButtons {
      buttons {
        label
        value
      }
      hasOther
    }
  }
`;

export const FRAGMENT_MANUAL_REGISTRATION = gql`
  fragment FragmentManualRegistration on ManualRegistration {
    id
    title
    description
    fields {
      ...FragmentManualRegistrationField
    }
  }
  ${FRAGMENT_MANUAL_REGISTRATION_FIELD}
`;

export const FRAGMENT_COURIER_DELIVERY = gql`
  fragment FragmentCourierDelivery on CourierDelivery {
    id
    courierService
    recipientCompany
    documentNumber
    status
    signedAt
    signature
    createdAt
  }
`;

export const FRAGMENT_MS_TEAMS = gql`
  fragment FragmentTeams on Teams {
    id
    tenantId
    caller {
      homeAccountId
      userId
      email
      name
    }
    createdAt
    client {
      id
      title
    }
  }
`;

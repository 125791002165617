import React, { useCallback, useMemo, useRef } from "react";

import orderBy from "lodash/orderBy";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import Button from "react-bootstrap/Button";

import { useForm } from "react-hook-form";

import Table from "../../../../components/Table";

import {
  QUERY_ENTITIES_DECEASED,
  QUERY_WAYFINDING_SCREENS,
} from "../../../../config/graphql/query";
import { useCurrentLanguage } from "../../../../lib/hooks/useCurrentLanguage";

type DeviceFieldValues = {
  announcement: string;
};

const TableRow = ({ item }: { item: IEntityWithDeceased }) => {
  const { id, title, properties } = item;

  const { t } = useTranslation(["common"]);

  return (
    <>
      <tr>
        <td>{title}</td>
        <td>{properties[0]?.title}</td>
        <td className="text-nowrap">
          <div className="d-flex">
            <Link to={`/msi-devices/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
    </>
  );
};

const MsiDeviceList = React.memo(() => {
  const { t } = useTranslation(["devices", "common"]);

  const funeralGroningenId = useRef<string>();

  const methods = useForm<DeviceFieldValues>();

  const language = useCurrentLanguage();

  useQuery<{
    wayfindingScreens: IWayfindingScreen[];
  }>(QUERY_WAYFINDING_SCREENS, {
    variables: { filter: { template: "funeral-groningen-multi" } },
    onCompleted: ({ wayfindingScreens }) => {
      funeralGroningenId.current = wayfindingScreens[0].id;
      methods.reset({
        announcement: wayfindingScreens[0].metadata.announcement[language],
      });
    },
    notifyOnNetworkStatusChange: true,
  });

  const variables = useMemo(
    () => ({
      filter: {
        type: "Space",
      },
    }),
    [],
  );

  const { data: entitiesDeceasedData } = useQuery<{
    entities?: Array<IEntityWithDeceased>;
  }>(QUERY_ENTITIES_DECEASED, {
    fetchPolicy: "no-cache",
    variables,
  });

  const entities: Array<IEntityWithDeceased> = orderBy(
    entitiesDeceasedData?.entities,
    [
      (item) => {
        const value = parseInt(item.title, 10);

        return value;
      },
    ],
    ["asc"],
  );

  const renderEntity = useCallback(
    (item: IEntityWithDeceased) => <TableRow key={item.id} item={item} />,
    [],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb" className="mb-4">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("devices:navList")}
          </li>
        </ol>
      </nav>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col">{t("devices:th.store")}</th>
            <th scope="col">{t("devices:th.Name")}</th>
            <th scope="col">{t("deceasedList:th.actions")}</th>
          </tr>
        </thead>
        <tbody>{entities.map(renderEntity)}</tbody>
      </Table>
    </div>
  );
});

export default MsiDeviceList;

import React, { useCallback, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { get } from "lodash";
import { toast } from "react-toastify";
import { Link, useHistory, useParams } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Input from "../../../../components/Input";
import {
  QUERY_ENTITIES_DECEASED,
  QUERY_ENTITY_DECEASED,
  QUERY_MINEW_ITEMS,
  QUERY_VISIONECT_ITEMS,
} from "../../../../config/graphql/query";
import {
  CREATE_DECEASED,
  UPDATE_DECEASED,
  DELETE_DECEASED,
  CREATE_ENTITY_PROPERTIES,
  RESTART_VISIONECT,
  RESTART_MINEW,
} from "../../../../config/graphql/mutation";
import { useCurrentClient } from "../../../../context/Client";

type Gender = "male" | "female";

type DeviceFieldValues = {
  title: string;
  gender: Gender;
  MondayStart: string;
  MondayEnd: string;
  TuesdayStart: string;
  TuesdayEnd: string;
  WednesdayStart: string;
  WednesdayEnd: string;
  ThursdayStart: string;
  ThursdayEnd: string;
  FridayStart: string;
  FridayEnd: string;
  SaturdayStart: string;
  SaturdayEnd: string;
  SundayStart: string;
  SundayEnd: string;
};

const MsiDevice = React.memo(() => {
  const [modal, setModal] = useState<"remove" | "force-update" | null>(null);
  const history = useHistory();
  const { t } = useTranslation(["minewClients", "common"]);
  const { entityId } = useParams<{ entityId: string }>();

  const defaultWeekFields = {
    MondayStart: "",
    MondayEnd: "",
    TuesdayStart: "",
    TuesdayEnd: "",
    WednesdayStart: "",
    WednesdayEnd: "",
    ThursdayStart: "",
    ThursdayEnd: "",
    FridayStart: "",
    FridayEnd: "",
    SaturdayStart: "",
    SaturdayEnd: "",
    SundayStart: "",
    SundayEnd: "",
  };

  const methods = useForm<DeviceFieldValues>({
    shouldFocusError: false,
    shouldUnregister: true,
    mode: "onChange",
    defaultValues: {
      ...defaultWeekFields, // Include week fields as default values
      gender: "male", // Set default value for gender
    },
  });

  const { data } = useQuery<{
    entity: IEntityWithDeceased;
  }>(QUERY_ENTITY_DECEASED, {
    variables: { id: entityId },
    onCompleted: ({ entity: { properties } }) => {
      const title = properties[0]?.title || ""; // Get the title field
      const weekFields = title.split("|"); // Split into array based on |
      const populatedValues = {
        MondayStart: weekFields[0]?.split(" - ")[0] || "",
        MondayEnd: weekFields[0]?.split(" - ")[1] || "",
        TuesdayStart: weekFields[1]?.split(" - ")[0] || "",
        TuesdayEnd: weekFields[1]?.split(" - ")[1] || "",
        WednesdayStart: weekFields[2]?.split(" - ")[0] || "",
        WednesdayEnd: weekFields[2]?.split(" - ")[1] || "",
        ThursdayStart: weekFields[3]?.split(" - ")[0] || "",
        ThursdayEnd: weekFields[3]?.split(" - ")[1] || "",
        FridayStart: weekFields[4]?.split(" - ")[0] || "",
        FridayEnd: weekFields[4]?.split(" - ")[1] || "",
        SaturdayStart: weekFields[5]?.split(" - ")[0] || "",
        SaturdayEnd: weekFields[5]?.split(" - ")[1] || "",
        SundayStart: weekFields[6]?.split(" - ")[0] || "",
        SundayEnd: weekFields[6]?.split(" - ")[1] || "",
      };

      methods.reset({
        ...populatedValues,
        gender: properties[0]?.gender || "male", // Reset other fields if needed
      });
    },
  });

  const deceasedId = get(data, "entity.properties[0].id");
  const deceasedName = get(data, "entity.properties[0].title");

  const { data: visionectItems } = useQuery(QUERY_VISIONECT_ITEMS, {
    variables: {
      filter: {
        entity: entityId,
      },
    },
  });

  const { data: minewItems } = useQuery(QUERY_MINEW_ITEMS, {
    variables: {
      filter: {
        entity: entityId,
      },
    },
  });

  const visionectId = get(visionectItems, "visionectItems[0].visionect.id");
  const minewId = get(minewItems, "minewItems[0].minew.id");

  const [onUpdate] = useMutation(UPDATE_DECEASED);
  const [onCreate] = useMutation(CREATE_DECEASED);
  const [onEntityAttachProperties] = useMutation(CREATE_ENTITY_PROPERTIES);
  const [onDelete, { loading: deleteLoading }] = useMutation(DELETE_DECEASED, {
    refetchQueries: [
      {
        query: QUERY_ENTITIES_DECEASED,
      },
    ],
  });

  const [onRestartVisionect, { loading: restartLoading }] =
    useMutation(RESTART_VISIONECT);

  const [onRestartMinew] = useMutation(RESTART_MINEW);
  const currentClient = useCurrentClient();

  const onForceUpdate = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      try {
        if (currentClient?.code === "msi") {
          await onRestartMinew({ variables: { id: minewId } });
          toast.success<string>(t("Minew successfully restarted"));
        } else {
          await onRestartVisionect({ variables: { id: visionectId } });
          toast.success<string>(t("deceased:toast.forcedUpdate"));
        }
      } catch (error) {
        toast.error<string>(t("deceased:toast.forcedUpdateError"));
      }
      setModal(null);
    },
    [onRestartVisionect, visionectId, t, minewId, onRestartMinew],
  );

  const onBeforeForceUpdate = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      setModal("force-update");
    },
    [],
  );

  const onRemove = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      try {
        await onDelete({ variables: { id: deceasedId } });

        toast.success<string>(
          t("minewClients:toast.deleted", {
            deceasedName,
          }),
        );

        history.replace(`/msi-devices`);
      } catch (error) {
        toast.error<string>(t("minewClients:toast.deletedError"));
      }
      setModal(null);
    },
    [onDelete, deceasedId, deceasedName, history, t],
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      setModal("remove");
    },
    [],
  );

  const onSubmit = async (variables: any) => {
    // Combine the start and end times into a single string for each day
    const weekFields = [
      `${variables.MondayStart} - ${variables.MondayEnd}`,
      `${variables.TuesdayStart} - ${variables.TuesdayEnd}`,
      `${variables.WednesdayStart} - ${variables.WednesdayEnd}`,
      `${variables.ThursdayStart} - ${variables.ThursdayEnd}`,
      `${variables.FridayStart} - ${variables.FridayEnd}`,
      `${variables.SaturdayStart} - ${variables.SaturdayEnd}`,
      `${variables.SundayStart} - ${variables.SundayEnd}`,
    ];
    const title = weekFields.join("|"); // Combine with | separator
    const finalVariables = {
      title, // Replace with the concatenated string
      gender: variables.gender, // Include the gender field
    };

    if (deceasedId) {
      try {
        await onUpdate({
          variables: { input: { id: deceasedId, ...finalVariables } },
        });

        toast.success<string>(t("minewClients:toast.updated"));
      } catch (error) {
        toast.error<string>(t("deceased:toast.updatedError"));
      }
    } else {
      try {
        const {
          data: {
            createDeceased: { id, title },
          },
        } = await onCreate({ variables: { input: finalVariables } });

        await onEntityAttachProperties({
          variables: {
            input: {
              id: entityId,
              properties: [id],
            },
          },
        });

        toast.success<string>(
          t("minewClients:toast.created", { deceasedName: title }),
        );
      } catch (error) {
        toast.error<string>(t("minewClients:toast.createdError"));
      }
    }
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/msi-devices">{t("device:nav.deceasedList")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {deceasedName ?? t("devices:nav.device")}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12">
              <div className="form-group row">
                <div className="col-sm-2" />
                <label className="col-sm-4 col-form-label">
                  {t("minewClients:device:form.StartTime")}
                </label>
                <label className="col-sm-4 col-form-label">
                  {t("minewClients:device:form.EndTime")}
                </label>
              </div>
            </div>
            {[
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ].map((day) => (
              <div className="col-12" key={day}>
                <div className="form-group row">
                  <label
                    htmlFor={`${day}Start`}
                    className="col-sm-2 col-form-label"
                  >
                    {t(`minewClients:device:form.${day}`)}
                  </label>
                  <div className="col-sm-4">
                    <Input
                      name={`${day}Start`}
                      type="time"
                      className="form-control"
                      rules={{
                        required: {
                          value: true,
                          message: t("device:form.error.required"),
                        },
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <Input
                      name={`${day}End`}
                      type="time"
                      className="form-control"
                      rules={{
                        required: {
                          value: true,
                          message: t("device:form.error.required"),
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-12">
              <input type="submit" className="btn btn-primary" />
              {deceasedId && (
                <button
                  onClick={onBeforeRemove}
                  className="btn btn-danger ml-3"
                >
                  {t("common:delete")}
                </button>
              )}
              <button
                className="btn btn-secondary ml-3"
                onClick={onBeforeForceUpdate}
              >
                {t("deceased:forceUpdate")}
              </button>
            </div>
          </div>
          <Controller
            name="gender"
            render={({ field }) => (
              <input type="hidden" {...field} value="male" />
            )}
          />
        </form>
      </FormProvider>
      <Modal
        show={!!modal}
        onHide={() => setModal(null)}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t(`deceased:modal.${modal}.title`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(`deceased:modal.${modal}.body`, {
            deceasedName,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModal(null)}>
            {t("common:cancel")}
          </Button>
          <Button
            variant="danger"
            disabled={restartLoading || deleteLoading}
            onClick={(e) => {
              if (modal === "force-update") {
                onForceUpdate(e);
              }
              if (modal === "remove") {
                onRemove(e);
              }
            }}
          >
            {modal === "remove" && t("common:delete")}
            {modal === "force-update" && t("common:restart")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default MsiDevice;

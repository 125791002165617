/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState } from "react";

import Cropper, { Area } from "react-easy-crop";
import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { getCroppedImg } from "../config/const/canvasUtils";
import { UncontrolledUploadInput } from "./FileUpload";

interface ImageCropPickerProps {
  name: string;
  value?: { file: string; filename: string };
  aspect?: number;
  allowsEditing?: boolean;
  onChange: (file: { file: string; filename: string }) => void;
}

const readFileAsDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const result = e.target?.result;

      if (!(result && typeof result === "string")) {
        return reject(new Error("Failed to read the file."));
      }

      return resolve(result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

const ImageCropPicker = (props: ImageCropPickerProps) => {
  const { name, value, allowsEditing = true, aspect, onChange } = props;

  const { t } = useTranslation(["screens", "common"]);

  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  const [image, setImage] = useState<{ file: File; data: string }>();

  const onSubmit = useCallback(async () => {
    if (!image) {
      return;
    }

    if (!croppedAreaPixels) {
      return;
    }

    const croppedImage = await getCroppedImg(image?.data, croppedAreaPixels, 0);

    setImage(undefined);

    onChange({ file: croppedImage, filename: image.file.name });
  }, [croppedAreaPixels, image, onChange]);

  return (
    <>
      <UncontrolledUploadInput
        multiple={false}
        name={name}
        onChange={async (file) => {
          if (allowsEditing) {
            return readFileAsDataURL(file).then((data) =>
              setImage({ data, file }),
            );
          }

          return readFileAsDataURL(file).then((data) =>
            onChange({ file: data, filename: file.name }),
          );
        }}
        dropZoneOptions={{
          accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
          },
          maxFiles: 1,
          maxSize: 3670016,
        }}
      />
      <Modal show={!!image} centered>
        <Modal.Header>
          <Modal.Title>{t("screens:screen.theme.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 500,
            }}
          >
            <Cropper
              image={image?.data}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onZoomChange={setZoom}
              onCropChange={setCrop}
              onCropComplete={(_croppedArea, croppedAreaPixels) =>
                setCroppedAreaPixels(croppedAreaPixels)
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setImage(undefined)}>
            {t("common:cancel")}
          </Button>
          <Button onClick={onSubmit}>{t("common:submit")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageCropPicker;

import "./scss/index.scss";
import "react-toastify/dist/ReactToastify.css";

import "./lib/dayjs";
import "./lib/i18n";

import { createRoot } from "react-dom/client";

import App from "./containers/Root";
import { getDomain } from "./lib/common/getDomain";

const updateMetaTags = () => {
  const domain = getDomain();

  document.title =
    domain === "frontassist.eu" ? "FrontAssist" : "Digital Reception";

  const favicon = document.querySelector("link[rel='icon']");
  if (favicon) {
    favicon.setAttribute(
      "href",
      domain === "frontassist.eu"
        ? "/frontassist.ico"
        : "/digitalreception.ico",
    );
  }
};

updateMetaTags();

const container = document.getElementById("root");

const root = createRoot(container!);

root.render(<App />);

import React, { useCallback, useState } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { Switch, useRouteMatch, Redirect, Link } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {
  QUERY_MINEW,
  QUERY_MINEW_DEVICES,
  QUERY_MINEWS,
} from "../../config/graphql/query";
import { DELETE_MINEW, RESTART_MINEW } from "../../config/graphql/mutation";

import Minew from "./Devices";

import Table from "../../components/Table";
import Route from "../../components/Route";
import RoleBlock from "../../components/RoleBlock";
// import MinewItems from "./Devices/Items";

const TableRow = ({ item }: { item: IMinew }) => {
  const { id, title, template, items } = item;
  const deviceData = items && items.length > 0 && items[0].minew?.device;
  console.log("deviceData", deviceData);
  const { data: minewData } = useQuery(QUERY_MINEW, {
    variables: { mac: deviceData && deviceData.mac },
  });
  const [modal, setModal] = useState<"remove" | "reload" | null>(null);

  const { t } = useTranslation(["minew", "common"]);

  const [onDelete, { loading: deleteLoading }] = useMutation(DELETE_MINEW, {
    refetchQueries: [
      { query: QUERY_MINEW_DEVICES, variables: { sort: { title: "ASC" } } },
    ],
    variables: { id },
  });

  const onRemove = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      try {
        await onDelete();

        toast.success<string>(t("minew:minews:toast.deleted"));
      } catch (error) {
        toast.error<string>(t("minew:minews:toast.deletedError"));
      }
      setModal(null);
    },
    [onDelete, t],
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      setModal("remove");
    },
    [],
  );

  const [onRestart, { loading: restartLoading }] = useMutation(RESTART_MINEW, {
    variables: { id },
  });
  const onReload = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      try {
        await onRestart();

        toast.success<string>(t("minew:minews:toast.reloaded"));
      } catch (error) {
        toast.error<string>(t("minew:minews:toast.reloadedError"));
      }
      setModal(null);
    },
    [onRestart, t],
  );

  const onBeforeReload = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      setModal("reload");
    },
    [],
  );
  const status =
    minewData?.minewDeviceByMac?.data?.isOnline === "1" ? "Online" : "Idle";

  return (
    <>
      <tr>
        <th scope="row" className="text-nowrap">
          {id}
        </th>
        <td>{title}</td>
        <td>{template}</td>
        <td>{deviceData && deviceData.mac}</td>
        <td>{minewData?.minewDeviceByMac?.data?.storeId}</td>
        <td>{status}</td>
        <td>{minewData?.minewDeviceByMac?.data?.battery} %</td>
        <td className="text-nowrap">
          <RoleBlock roles={["ADMIN"]}>
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={onBeforeRemove}
            >
              {t("common:delete")}
            </Button>
          </RoleBlock>
          <Link to={`/minew-device/${id}`}>
            <Button size="sm" variant="primary" className="mr-2">
              {t("common:view")}
            </Button>
          </Link>
          <Button size="sm" variant="secondary" onClick={onBeforeReload}>
            {t("common:reload")}
          </Button>
        </td>
      </tr>
      <Modal
        show={!!modal}
        onHide={() => setModal(null)}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t(`wayfinding:wayfindings.modal.${modal}.title`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(`wayfinding:wayfindings.modal.${modal}.body`, {
            title: item.title,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setModal(null)}>
            {t("common:cancel")}
          </Button>
          <Button
            size="sm"
            variant="danger"
            disabled={restartLoading || deleteLoading}
            onClick={(e) => {
              if (modal === "reload") {
                onReload(e);
              }
              if (modal === "remove") {
                onRemove(e);
              }
            }}
          >
            {modal === "remove" && t("common:delete")}
            {modal === "reload" && t("common:reload")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const MinewDevice = React.memo(() => {
  const match = useRouteMatch();

  const { t } = useTranslation(["minew", "common"]);

  const { data } = useQuery(QUERY_MINEWS, {
    variables: { sort: { title: "ASC" } },
  });

  console.log(data);

  const minewDevices: Array<IMinew> = data?.minews ?? [];

  const renderMinew = useCallback(
    (item: IMinew) => <TableRow key={item.id} item={item} />,
    [],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("minew:minews.nav.minew")}
          </li>
        </ol>
      </nav>
      <RoleBlock roles={["ADMIN"]}>
        <div className="d-flex justify-content-end align-items-center mb-3">
          <Link
            to={`${match.path}/new`}
            type="button"
            className="btn btn-primary"
          >
            {t("minew:minews.button.add")}
          </Link>
        </div>
      </RoleBlock>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t("minew:minews.th.name")}</th>
            <th scope="col">{t("minew:minews.th.template")}</th>
            <th scope="col">{t("minew:minews.th.mac")}</th>
            <th scope="col">{t("minew:minews.th.storeId")}</th>
            <th scope="col">{t("minew:minews.th.status")}</th>
            <th scope="col">{t("minew:minews.th.battery")}</th>
            <th scope="col">{t("minew:minews.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{minewDevices.map(renderMinew)}</tbody>
      </Table>
    </div>
  );
});

const MinewsRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route.Private exact path={path}>
        <MinewDevice />
      </Route.Private>
      <Route.Role exact path={`${path}/new`} roles={["ADMIN"]} redirect={path}>
        <Minew />
      </Route.Role>
      <Route.Private path={`${path}/:minewId`}>
        <Minew />
      </Route.Private>
      <Redirect to={path} />
    </Switch>
  );
});

export default MinewsRouter;

/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from "dayjs";
import React, { useCallback, useEffect } from "react";

import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";

import clsx from "clsx";
import keys from "lodash/keys";

import DatePicker from "react-datepicker";

import { toast } from "react-toastify";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import {
  MUTATION_CREATE_SCREEN_SCHEDULE,
  MUTATION_UPDATE_SCREEN_SCHEDULE,
} from "../../../../../../config/graphql/mutation";
import {
  QUERY_DIGITAL_RECEPTION,
  QUERY_DIGITAL_RECEPTION_SCHEDULES,
  QUERY_SCREEN_SCHEDULE,
} from "../../../../../../config/graphql/query";

const days = [1, 2, 3, 4, 5, 6, 0];

// const TIME_INTERVAL = 1;
const TIME_INTERVAL = 15;

interface FieldValues
  extends Pick<
    ISchedule,
    "startDate" | "startTime" | "endDate" | "endTime" | "disabled"
  > {
  weekdays: Record<number, boolean>;
  allDay: boolean;
  allDates: boolean;
}

const ScheduleTimeFormItem = React.memo(() => {
  const { t } = useTranslation(["schedule"]);

  return (
    <div className="row">
      <div className="col-4">
        <Controller
          name="startTime"
          rules={{
            validate: {
              validate: (value) => {
                if (!value) {
                  return t("schedule:input.error.startTime.required");
                }

                const date = dayjs(value, "HH:mm");

                if (!date.isValid()) {
                  return t("schedule:input.error.startTime.required");
                }

                return true;
              },
            },
          }}
          render={({
            field: { value, name, onChange },
            fieldState: { error },
          }) => {
            return (
              <div className="form-group">
                <label htmlFor={name}>
                  {t("schedule:input.label.startTime")}
                </label>
                <DatePicker
                  selected={value ? dayjs(value, "HH:mm").toDate() : undefined}
                  onChange={(date) => {
                    onChange(dayjs(date).format("HH:mm"));
                  }}
                  className={clsx("form-control", {
                    "is-invalid": !!error,
                  })}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={TIME_INTERVAL}
                  dateFormat="HH:mm"
                />
                <div className="d-none is-invalid" />
                <div className="invalid-feedback">{error?.message}</div>
              </div>
            );
          }}
        />
        <Controller
          name="endTime"
          rules={{
            validate: {
              validate: (value) => {
                if (!value) {
                  return t("schedule:input.error.endTime.required");
                }

                const date = dayjs(value, "HH:mm");

                if (!date.isValid()) {
                  return t("schedule:input.error.endTime.required");
                }

                // const isSameOrAfter = date.isSameOrAfter(
                //   dayjs(startTime, "HH:mm"),
                // );

                // if (!isSameOrAfter) {
                //   return t("schedule:input.error.endTime.isBefore");
                // }

                return true;
              },
            },
          }}
          render={({
            field: { value, name, onChange },
            fieldState: { error },
          }) => {
            return (
              <div className="form-group">
                <label htmlFor={name}>
                  {t("schedule:input.label.endTime")}
                </label>
                <DatePicker
                  selected={value ? dayjs(value, "HH:mm").toDate() : undefined}
                  onChange={(date) => {
                    onChange(dayjs(date).format("HH:mm"));
                  }}
                  className={clsx("form-control", {
                    "is-invalid": !!error,
                  })}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={TIME_INTERVAL}
                  dateFormat="HH:mm"
                />
                <div className="d-none is-invalid" />
                <div className="invalid-feedback">{error?.message}</div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
});

const ScheduleTime = React.memo(() => {
  const { t } = useTranslation(["schedule"]);

  const allDay = useWatch<FieldValues, "allDay">({ name: "allDay" });

  return (
    <>
      <Controller
        name="allDay"
        render={({ field: { value, name, onChange, ...rest } }) => {
          return (
            <div className="form-check mb-4">
              <input
                id={name}
                {...rest}
                type="checkbox"
                className="form-check-input"
                checked={value === true}
                onChange={() => onChange(!value)}
              />
              <label className="form-check-label" htmlFor={name}>
                {t("schedule:input.label.allDay")}
              </label>
            </div>
          );
        }}
      />
      {!allDay && <ScheduleTimeFormItem />}
    </>
  );
});

const ScheduleDateFormItem = React.memo(() => {
  const { t } = useTranslation(["schedule"]);

  const startDate = useWatch<FieldValues, "startDate">({ name: "startDate" });

  return (
    <div className="row">
      <div className="col-4">
        <Controller
          name="startDate"
          rules={{
            validate: {
              validate: (value) => {
                if (!value) {
                  return t("schedule:input.error.startDate.required");
                }

                const date = dayjs(value, "YYYY-MM-DD");

                if (!date.isValid()) {
                  return t("schedule:input.error.startDate.required");
                }

                return true;
              },
            },
          }}
          render={({
            field: { value, name, onChange },
            fieldState: { error },
          }) => {
            return (
              <div className="form-group">
                <label htmlFor={name}>
                  {t("schedule:input.label.startDate")}
                </label>
                <DatePicker
                  selected={
                    value ? dayjs(value, "YYYY-MM-DD").toDate() : undefined
                  }
                  onChange={(date) => {
                    onChange(dayjs(date).format("YYYY-MM-DD"));
                  }}
                  className={clsx("form-control", {
                    "is-invalid": !!error,
                  })}
                  minDate={new Date()}
                />
                <div className="d-none is-invalid" />
                <div className="invalid-feedback">{error?.message}</div>
              </div>
            );
          }}
        />
        <Controller
          name="endDate"
          rules={{
            validate: {
              validate: (value) => {
                if (!value) {
                  return t("schedule:input.error.endDate.required");
                }

                const date = dayjs(value, "YYYY-MM-DD");

                if (!date.isValid()) {
                  return t("schedule:input.error.endDate.required");
                }

                const isSameOrAfter = date.isSameOrAfter(
                  dayjs(startDate, "YYYY-MM-DD"),
                );

                if (!isSameOrAfter) {
                  return t("schedule:input.error.endDate.isBefore");
                }

                return true;
              },
            },
          }}
          render={({
            field: { value, name, onChange },
            fieldState: { error },
          }) => {
            return (
              <div className="form-group">
                <label htmlFor={name}>
                  {t("schedule:input.label.endDate")}
                </label>
                <DatePicker
                  selected={value ? dayjs(value).toDate() : undefined}
                  onChange={(date) => {
                    onChange(dayjs(date).format("YYYY-MM-DD"));
                  }}
                  className={clsx("form-control", {
                    "is-invalid": !!error,
                  })}
                  minDate={new Date()}
                />
                <div className="d-none is-invalid" />
                <div className="invalid-feedback">{error?.message}</div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
});

const ScheduleDate = React.memo(() => {
  const { t } = useTranslation(["schedule"]);

  const allDates = useWatch<FieldValues, "allDates">({ name: "allDates" });

  return (
    <>
      <Controller
        name="allDates"
        render={({ field: { value, name, onChange, ...rest } }) => {
          return (
            <div className="form-check mb-4">
              <input
                id={name}
                {...rest}
                type="checkbox"
                className="form-check-input"
                checked={value === true}
                onChange={() => onChange(!value)}
              />
              <label className="form-check-label" htmlFor={name}>
                {t("schedule:input.label.allDates")}
              </label>
            </div>
          );
        }}
      />
      {!allDates && <ScheduleDateFormItem />}
    </>
  );
});

const DigitalReceptionSchedule = React.memo(() => {
  const { t } = useTranslation(["screens", "common"]);

  const history = useHistory();

  const { screenId, scheduleId } = useParams<{
    screenId: string;
    scheduleId?: string;
  }>();

  const { data: screen } = useQuery<{ digitalReception: IDigitalReception }>(
    QUERY_DIGITAL_RECEPTION,
    {
      skip: !screenId,
      variables: { id: screenId },
    },
  );

  const methods = useForm<FieldValues>({
    shouldFocusError: false,
    defaultValues: {
      allDay: false,
      allDates: true,
      weekdays: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: false,
        0: false,
      },
      startTime: "17:00",
      endTime: "09:00",
      startDate: undefined,
      endDate: undefined,
      disabled: false,
    },
  });

  const { data: screenScheduleData, loading } = useQuery<{
    screenSchedule: IScreenSchedule;
  }>(QUERY_SCREEN_SCHEDULE, {
    variables: {
      id: scheduleId,
    },
    skip: !scheduleId,
  });

  useEffect(() => {
    if (screenScheduleData?.screenSchedule) {
      const {
        screenSchedule: {
          schedule: { weekdays, ...rest },
        },
      } = screenScheduleData;

      const allDay = !rest.startTime && !rest.endTime;
      const allDates = !(rest.startDate && rest.endDate);

      methods.reset({
        ...rest,
        allDay,
        allDates,
        weekdays: days.reduce(
          (acc, day) => ({ ...acc, [day]: weekdays.includes(day) }),
          {},
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenScheduleData]);

  const [onSave, { loading: saving }] = useMutation(
    scheduleId
      ? MUTATION_UPDATE_SCREEN_SCHEDULE
      : MUTATION_CREATE_SCREEN_SCHEDULE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_SCHEDULES,
          variables: { id: screenId },
        },
      ],
    },
  );

  const onSubmit = useCallback(
    (variables: FieldValues) => {
      const { weekdays, allDay, allDates, disabled } = variables;

      let schedule: Partial<ISchedule> = {
        disabled,
        weekdays: keys(weekdays)
          .filter((key) => weekdays[parseInt(key, 10)])
          .map((value) => parseInt(value, 10)),
      };

      if (!allDay) {
        schedule = {
          ...schedule,
          endTime: variables.endTime,
          startTime: variables.startTime,
        };
      }

      if (!allDates) {
        schedule = {
          ...schedule,
          startDate: variables.startDate,
          endDate: variables.endDate,
        };
      }

      return onSave({
        variables: {
          input: {
            type: "sleep",
            id: scheduleId,
            screens: [screenId],
            schedule,
          },
        },
      })
        .then(() => {
          toast.success<string>(
            screenId
              ? t("digitalReceptionSchedule:toast.updated")
              : t("digitalReceptionSchedule:toast.created"),
          );

          history.replace(`/digital-receptions/${screenId}/settings/schedules`);
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [history, onSave, scheduleId, screenId, t],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/digital-receptions">
              {t("screens:screensRouter.nav.screens")}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/digital-receptions/${screenId}`}>
              {screen?.digitalReception?.title ??
                t("screens:screen.screenRoute.nav.screen")}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/digital-receptions/${screenId}/settings`}>
              {t("screens:screen.screenRoute.nav.settings")}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/digital-receptions/${screenId}/settings/schedules`}>
              {t("screens:screen.screenRoute.nav.schedules")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("schedule:title")}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-12">
            <div className="form-group mb-4">
              <label htmlFor="weekdays">
                {t("schedule:input.label.weekdays")}
              </label>
              <div>
                {days.map((day) => (
                  <Controller
                    key={day}
                    name={`weekdays.${day}`}
                    render={({ field: { value, onChange, ...rest } }) => {
                      return (
                        <div className="form-check form-check-inline">
                          <input
                            {...rest}
                            id={`${day}`}
                            type="checkbox"
                            className="form-check-input"
                            checked={value === true}
                            onChange={() => onChange(!value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`${day}`}
                          >
                            {dayjs().weekday(day).format("ddd")}
                          </label>
                        </div>
                      );
                    }}
                  />
                ))}
              </div>
            </div>
            <ScheduleDate />

            <ScheduleTime />

            <Controller
              name="disabled"
              render={({ field: { value, name, onChange, ...rest } }) => {
                return (
                  <div className="form-check mb-4">
                    <input
                      id={name}
                      {...rest}
                      type="checkbox"
                      className="form-check-input"
                      checked={value === true}
                      onChange={() => onChange(!value)}
                    />
                    <label className="form-check-label" htmlFor={name}>
                      {t("schedule:input.label.disabled")}
                    </label>
                  </div>
                );
              }}
            />
          </div>

          <div className="col-12">
            <input
              type="submit"
              className="btn btn-primary"
              value={t("digitalReceptionSchedule:button.submit")}
              disabled={loading || saving}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default DigitalReceptionSchedule;

import React from "react";

import { Switch, useRouteMatch, Redirect, useParams } from "react-router-dom";

import Route from "../../components/Route";
import FileUploadProvider from "../../components/FileUpload";

import DigitalReception from "./DigitalReception";
import DigitalReceptions from "./DigitalReceptions";
import DigitalReceptionSchedule from "./DigitalReception/Settings/Schedules/Schedule";
import EntranceAgreementFile from "./DigitalReception/Settings/EntranceAgreementFile/index";
import ManualRegistration from "./DigitalReception/Settings/ManualRegistrations/ManualRegistration";
import ManualRegistrationField from "./DigitalReception/Settings/ManualRegistrations/ManualRegistrationField";

const DigitalReceptionsRouter = React.memo(() => {
  const { path } = useRouteMatch();

  const { screenId } = useParams<{ screenId: string }>();

  return (
    <Switch>
      <Route.Base exact path={path}>
        <DigitalReceptions />
      </Route.Base>
      <Route.Role exact path={`${path}/new`} roles={["ADMIN"]} redirect={path}>
        <DigitalReception />
      </Route.Role>
      <Route.Base exact path={`${path}/:screenId/settings/schedules/new`}>
        <DigitalReceptionSchedule />
      </Route.Base>
      <Route.Base
        exact
        path={`${path}/:screenId/settings/schedules/:scheduleId`}
      >
        <DigitalReceptionSchedule />
      </Route.Base>
      <Route.Role
        exact
        path={`${path}/:screenId/settings/entrance-agreements/:agreementId/files/new`}
        roles={["ADMIN", "CLIENT_ADMIN"]}
        redirect={`${path}/${screenId}/settings`}
      >
        <FileUploadProvider>
          <EntranceAgreementFile />
        </FileUploadProvider>
      </Route.Role>
      <Route.Role
        exact
        path={`${path}/:screenId/settings/entrance-agreements/:agreementId/files/:agreementFileId`}
        roles={["ADMIN", "CLIENT_ADMIN"]}
        redirect={`${path}/${screenId}/settings`}
      >
        <FileUploadProvider>
          <EntranceAgreementFile />
        </FileUploadProvider>
      </Route.Role>
      <Route.Role
        exact
        path={`${path}/:screenId/settings/manual-registrations/new`}
        roles={["ADMIN", "CLIENT_ADMIN"]}
        redirect={`${path}/${screenId}/settings`}
      >
        <ManualRegistration />
      </Route.Role>
      <Route.Role
        exact
        path={`${path}/:screenId/settings/manual-registrations/:manualRegistrationId`}
        roles={["ADMIN", "CLIENT_ADMIN"]}
        redirect={`${path}/${screenId}/settings`}
      >
        <ManualRegistration />
      </Route.Role>
      <Route.Role
        exact
        path={`${path}/:screenId/settings/manual-registrations/:manualRegistrationId/fields/new`}
        roles={["ADMIN", "CLIENT_ADMIN"]}
        redirect={`${path}/${screenId}/settings`}
      >
        <ManualRegistrationField />
      </Route.Role>
      <Route.Role
        exact
        path={`${path}/:screenId/settings/manual-registrations/:manualRegistrationId/fields/:manualRegistrationFieldId`}
        roles={["ADMIN", "CLIENT_ADMIN"]}
        redirect={`${path}/${screenId}/settings`}
      >
        <ManualRegistrationField />
      </Route.Role>
      <Route.Base path={`${path}/:screenId`}>
        <DigitalReception />
      </Route.Base>

      <Redirect to={path} />
    </Switch>
  );
});

export default DigitalReceptionsRouter;

import { useQuery } from "@apollo/client";
import { QUERY_BROTHER_PRINTER_TEMPLATE_OPTIONS } from "../../config/graphql/query";

interface IBrotherPrinterTemplateOptionsProps<T> {
  clientId?: string;
  onCompleted?: (data: T) => void;
}
export const useBrotherPrinterTemplateOptions = ({
  clientId,
  onCompleted,
}: IBrotherPrinterTemplateOptionsProps<IBadgeOptionsResponse>) => {
  const { data } = useQuery<IBadgeOptionsResponse>(
    QUERY_BROTHER_PRINTER_TEMPLATE_OPTIONS,
    {
      skip: !clientId,
      variables: { client: clientId },
      onCompleted,
    },
  );

  return data?.brotherPrinterTemplateOptions;
};
